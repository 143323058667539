import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../services/auth';
import { api } from '../services/api';
import DOMPurify from 'dompurify';

interface UserProfile {
  id: string;
  email: string;
  full_name: string;
}

function Profile() {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [fullName, setFullName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const currentUser = await auth.getCurrentUser();
        if (currentUser) {
          const profile = await auth.getUserProfile(currentUser.id);
          setUser(profile);
          setFullName(profile.full_name);
        }
      } catch (err) {
        setError('Failed to fetch user profile.');
      }
    };
    fetchUserProfile();
  }, []);

  const handleUpdateProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    try {
      if (user) {
        const sanitizedFullName = DOMPurify.sanitize(fullName).trim();
        if (sanitizedFullName.length > 50) {
          setError('Full name must be 50 characters or less.');
          return;
        }
        await auth.updateProfile(user.id, { full_name: sanitizedFullName });
        api.clearUserCache(user.id);
        setMessage('Profile updated successfully.');
      }
    } catch (err) {
      setError('Failed to update profile.');
    }
  };

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    const sanitizedNewPassword = DOMPurify.sanitize(newPassword);
    const sanitizedConfirmPassword = DOMPurify.sanitize(confirmPassword);
    if (sanitizedNewPassword !== sanitizedConfirmPassword) {
      setError("Passwords don't match");
      return;
    }
    if (sanitizedNewPassword.length > 50) {
      setError('Password must be 50 characters or less.');
      return;
    }
    try {
      await auth.updatePassword(sanitizedNewPassword);
      api.clearUserCache(user!.id);
      setMessage('Password changed successfully.');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError('Failed to change password.');
    }
  };

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value);
    setter(sanitizedValue.slice(0, 50));
  };

  if (!user) return <div>Loading...</div>;

  return (
    <div className="max-w-md mx-auto mt-8">
      <Link to="/products" className="inline-block mb-4 text-blue-500 hover:text-blue-700">
        &lt;-- Back to Products
      </Link>
      
      <h2 className="text-2xl font-bold mb-4">User Profile</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {message && <p className="text-green-500 mb-4">{message}</p>}
      
      <form onSubmit={handleUpdateProfile} className="mb-8">
        <div className="mb-4">
          <label htmlFor="email" className="block mb-1">Email</label>
          <input
            type="email"
            id="email"
            value={user.email}
            disabled
            className="w-full px-3 py-2 border rounded bg-gray-100"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="fullName" className="block mb-1">Full Name</label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={handleInputChange(setFullName)}
            maxLength={50}
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
          Update Profile
        </button>
      </form>

      <h3 className="text-xl font-bold mb-4">Change Password</h3>
      <form onSubmit={handleChangePassword}>
        <div className="mb-4">
          <label htmlFor="newPassword" className="block mb-1">New Password</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={handleInputChange(setNewPassword)}
            maxLength={50}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block mb-1">Confirm New Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={handleInputChange(setConfirmPassword)}
            maxLength={50}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <button type="submit" className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600">
          Change Password
        </button>
      </form>
    </div>
  );
}

export default Profile;