import { supabase } from './supabase'
import { User, Session, AuthChangeEvent, AuthResponse, UserResponse } from '@supabase/supabase-js'

interface UserProfile {
  id: string;
  email: string;
  full_name: string;
  is_active: boolean;
}

export const auth = {
  async login(email: string, password: string): Promise<AuthResponse> {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password })
    if (error) throw error
    if (data.user) {
      await this.updateLastSignIn(data.user.id)
    }
    return { data, error }
  },

  async register(email: string, password: string, fullName: string): Promise<AuthResponse> {
    console.log('Starting registration process for:', email);
    const { data, error } = await supabase.auth.signUp({ 
      email, 
      password,
      options: {
        data: {
          full_name: fullName,
        },
        emailRedirectTo: `${window.location.origin}/verify-email`
      }
    });
  
    if (error) {
      console.error('Registration error:', error);
      throw error;
    }
  
    console.log('Auth registration successful:', data);
  
    // The user is created but not confirmed
    if (data.user && !data.user.confirmed_at) {
      console.log('User created but not confirmed. Verification email sent.');
    }
  
    return { data, error };
  },

  async logout(): Promise<void> {
    const { error } = await supabase.auth.signOut()
    if (error) throw error
  },

  async getCurrentUser(): Promise<User | null> {
    try {
      const { data, error } = await supabase.auth.getUser()
      if (error) {
        if (error.name === 'AuthSessionMissingError') {
          console.log('No active session')
          return null
        }
        throw error
      }
      return data.user
    } catch (error) {
      console.error('Error getting current user:', error)
      return null
    }
  },

  async getSession(): Promise<Session | null> {
    try {
      const { data, error } = await supabase.auth.getSession()
      if (error) throw error
      return data.session
    } catch (error) {
      console.error('Error getting session:', error)
      return null
    }
  },

  async resetPassword(email: string): Promise<void> {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    })
    if (error) throw error
  },

  async updatePassword(newPassword: string): Promise<UserResponse> {
    const { data, error } = await supabase.auth.updateUser({ password: newPassword })
    if (error) throw error
    return { data, error }
  },

  async updateProfile(userId: string, updates: Partial<UserProfile>): Promise<UserProfile> {
    const { data, error } = await supabase
      .from('users')
      .update(updates)
      .eq('id', userId)
      .single()
    if (error) throw error
    return data
  },

  async verifyEmail(token: string): Promise<{ data: { user: User | null; session: Session | null } | null; error: Error | null }> {
    const { data, error } = await supabase.auth.verifyOtp({ token_hash: token, type: 'signup' })
    if (error) {
      console.error('Verification error:', error)
      return { data: null, error }
    }
    // The user profile creation is now handled by the database trigger
    console.log('Email verified successfully')
    return { data, error: null }
  },

  async createUserProfile(userId: string, email: string, fullName: string): Promise<void> {
    const { error } = await supabase
      .from('users')
      .insert({
        id: userId,
        email: email,
        full_name: fullName,
        is_active: true
      })
    if (error) throw error
  },

  async updateLastSignIn(userId: string): Promise<void> {
    const { error } = await supabase
      .from('users')
      .update({ last_sign_in_at: new Date().toISOString() })
      .eq('id', userId)
    if (error) console.error('Error updating last sign in:', error)
  },

  async sendVerificationEmail(email: string): Promise<void> {
    const { error } = await supabase.auth.resend({
      type: 'signup',
      email: email,
      options: {
        emailRedirectTo: `${window.location.origin}/verify-email`
      }
    })
    if (error) throw error
  },

  async getUserProfile(userId: string): Promise<UserProfile> {
    const { data, error } = await supabase
      .from('users')
      .select('*')
      .eq('id', userId)
      .single()
    if (error) throw error
    return data
  },

  async updateUserStatus(userId: string, isActive: boolean): Promise<void> {
    const { error } = await supabase
      .from('users')
      .update({ is_active: isActive })
      .eq('id', userId)
    if (error) throw error
  },

  onAuthStateChange(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return supabase.auth.onAuthStateChange(callback)
  }
}