import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { api, Category, CategoryStats, Question, Note, QuizHistory, QuizQuestionData, LocationState, QuizSummary, FilterCounts, UserAnswer } from '../../services/api';
import { Calculator as CalculatorIcon, Table, HelpCircle, ChevronRight, CheckCircle, Wrench, X } from 'lucide-react';
import PopupModal from './common/PopupModal';
import { ModalContext } from '../../App'; // Import the ModalContext
import QuizResetCategoryPopup from './QuizResetCategoryPopup'; // Adjust the import path as necessary
import QuizCustomizer from './QuizCustomizer';
import QuizSummaryFilter from './QuizSummaryFilter';
import { parseQuestionContent } from '../../utils/questionUtils';
import { useAuth } from '../AuthProvider';
import { useQuizData } from '../../hooks/useQuizData';
import { useQuizHistory } from '../../hooks/useQuizHistory';
import { useCategoryStats } from '../../hooks/useCategoryStats';
import LoadingScreen from './common/LoadingScreen';
import EndTestlet from './quiz-tools/EndTestlet';
import Calculator from './quiz-tools/Calculator';
import Spreadsheet from './quiz-tools/Spreadsheet';
import FeedbackPopup from './quiz-tools/FeedbackPopup';
import TestModeToggle from './quiz-tools/TestModeToggle';
import { Button } from '../../components/ui/Button';
import useKeyPress from '../../hooks/useKeyPress';

interface QuizProps {
  courseId: string;
  userId: string;
}

type ConfidenceLevel = 'guessing' | 'maybe' | 'confident';
type CurrentView = 'categories' | 'quiz' | 'summary';
let currentView: CurrentView = 'categories';  // Default to a valid CurrentView value

const MAX_NOTE_LENGTH = 2000;

function Quiz({ courseId, userId }: QuizProps) {
  const { user } = useAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [keslerQFilter, setKeslerQFilter] = useState<string[]>(['unanswered']);
  const [summaryFilter, setSummaryFilter] = useState('all');
  const [showCustomizer, setShowCustomizer] = useState(false);
  const [customQuestionCount, setCustomQuestionCount] = useState(10);
  const [showPopup, setShowPopup] = useState(false);
  const [showResetPopup, setShowResetPopup] = useState(false);
  const [activeQuiz, setActiveQuiz] = useState<QuizHistory | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentView, setCurrentView] = useState<'categories' | 'quiz' | 'summary'>('categories');
  const [isNoteTextareaFocused, setIsNoteTextareaFocused] = useState(false);
  const [isSpreadsheetFocused, setIsSpreadsheetFocused] = useState(false);

  const {resumeQuizId, viewQuizId, quizData, from, showSummary: initialShowSummary, quizCompleted: initialQuizCompleted } = location.state || {};
  const [showSummary, setShowSummary] = useState(initialShowSummary || false);
  const [quizCompleted, setQuizCompleted] = useState(initialQuizCompleted || false);
  const [viewingCompletedQuiz, setViewingCompletedQuiz] = useState(false);
  const { setIsModalOpen } = useContext(ModalContext);
  const activeQuizRef = useRef<any>(null);
  const [closingSummary, setClosingSummary] = useState(false);
  const [isResumingQuiz, setIsResumingQuiz] = useState(false);
  const [isEndingQuiz, setIsEndingQuiz] = useState(false);
  const [isLoadingNextQuestion, setIsLoadingNextQuestion] = useState(false);
  const [isLoadingQuizSummary, setIsLoadingQuizSummary] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confidenceHistory, setConfidenceHistory] = useState<{[questionId: string]: string | null}>({});
  const [focusedConfidenceLevel, setFocusedConfidenceLevel] = useState<number>(-1);
  const [hasInitiallyFetched, setHasInitiallyFetched] = useState(false);

  //QUIZ TOOLS
  const [showEndTestlet, setShowEndTestlet] = useState(false);
  const [showCalculator, setShowCalculator] = useState(false);
  const [showSpreadsheet, setShowSpreadsheet] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isTestMode, setIsTestMode] = useState(false);
  const [showEndTestletModal, setShowEndTestletModal] = useState(false);
  const [isLastQuestion, setIsLastQuestion] = useState(false);

 // Hooks
  const {
    categoriesData,
    categoriesLoading,
    categoriesError,
    useAvailableQuestionCounts,
    resetCategories,
    useActiveQuiz,
    resetActiveQuizCheck,
    useFilteredQuizHistory,
  } = useQuizData(courseId, currentUser?.id);

  // Keep the existing categoryStats state
  const [categoryStats, setCategoryStats] = useState<{ [key: string]: CategoryStats }>({});


  // Use the new custom hook
  const {
    categoryStats: cachedCategoryStats,
    isLoading: categoryStatsLoading,
    error: categoryStatsError,
    invalidateCategoryStats
  } = useCategoryStats(courseId, user?.id || '', selectedCategories);

  const {
    data: availableQuestionCounts,
    isLoading: availableQuestionCountsLoading,
    error: availableQuestionCountsError
  } = useAvailableQuestionCounts(selectedCategories, keslerQFilter, !!currentUser);

  const {
    data: quizHistory,
    isLoading: quizHistoryLoading,
    error: quizHistoryError,
    refetchQuizHistory
  } = useQuizHistory(currentUser?.id, courseId, summaryFilter, true, false);
  
  const [filterCounts, setFilterCounts] = useState({
    all: 0,
    correct: 0,
    incorrect: 0,
    confident: 0,
    maybe: 0,
    guessing: 0
  });

  const [isLoading, setIsLoading] = useState(false);

  //Screen Scrolling
  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  //LOCAL CATEGORY STATS
  const [localCategoryStats, setLocalCategoryStats] = useState<{[key: string]: CategoryStats}>({});
  const queryClient = useQueryClient();
  const [isFinishingQuiz, setIsFinishingQuiz] = useState(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  
  // Update other parts of the file that use these confidence levels
  const { data: activeQuizData, isLoading: isActiveQuizLoading } = useActiveQuiz();
  const confidenceLevels: ConfidenceLevel[] = ['guessing', 'maybe', 'confident'];
  const [categoriesStructure, setCategoriesStructure] = useState<{[key: string]: Category[]}>({});
  const [questions, setQuestions] = useState<(Question & { note: Note | null })[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState<UserAnswer>({});
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [isResettingCategories, setIsResettingCategories] = useState(false);
  
  const [showExplanation, setShowExplanation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const MemoizedQuizSummaryFilter = React.memo(QuizSummaryFilter);
  const [note, setNote] = useState('');
  const [showNoteEditor, setShowNoteEditor] = useState(false);
  const [currentNote, setCurrentNote] = useState<Note | null>(null);
  const [quizStarted, setQuizStarted] = useState(false);
  const [noteContent, setNoteContent] = useState<string>('');
  const [isEditingNote, setIsEditingNote] = useState<boolean>(false);
  const [quizSummary, setQuizSummary] = useState<QuizSummary[]>([]);
  const [lessonId, setLessonId] = useState<string | null>(null);
  const [simulationId, setSimulationId] = useState<string | null>(null);
  const [confidenceLevel, setConfidenceLevel] = useState<ConfidenceLevel | null>(null);
  const [editingNoteIndex, setEditingNoteIndex] = useState<number | null>(null);
  // Add these new state variables at the top of your component
  const [editingNotes, setEditingNotes] = useState<{ [key: number]: boolean }>({});
  const [noteContents, setNoteContents] = useState<{ [key: number]: string }>({});
  const [notes, setNotes] = useState<{ [questionId: string]: Note }>({});
  const [currentQuizHistoryId, setCurrentQuizHistoryId] = useState<string | null>(null);
  const [currentQuizSummary, setCurrentQuizSummary] = useState<QuizSummary[]>([]);
  const [localQuizHistory, setLocalQuizHistory] = useState<QuizHistory[]>([]);
  const [isFilteringLocally, setIsFilteringLocally] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [averageTime, setAverageTime] = useState(0);
  const [incompleteQuiz, setIncompleteQuiz] = useState<any | null>(null);
  
  const [showMobileTools, setShowMobileTools] = useState(false);
  // Pagination helper functions
  const [currentPage, setCurrentPage] = useState(1);
  //quiz history - 20 per page
  const quizzesPerPage = 20;
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  //quiz summary pages - 10 per page
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = quizSummary.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(quizSummary.length / itemsPerPage);
  const [selectAll, setSelectAll] = useState(false);
  const [isFinishing, setIsFinishing] = useState(false);
  // QUIZ TIMER
  const [quizStartTime, setQuizStartTime] = useState<number | null>(null);
  const [questionStartTime, setQuestionStartTime] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [pausedTime, setPausedTime] = useState<number>(0);
  const [explanationStartTime, setExplanationStartTime] = useState<number | null>(null);
  const quizStartTimeRef = useRef<number | null>(null);
  const pausedTimeRef = useRef<number>(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  // Calculations
  const totals = useMemo(() => {
    return Object.values(categoriesData?.structuredCategories || {}).reduce((acc, subCategories) => {
      subCategories.forEach(category => {
        if (category.sub_question_category_name !== 'All') {
          acc.totalQuestions += category.total_questions || 0;
          acc.unansweredQuestions += category.unanswered_questions || 0;
          acc.correctAnswers += (category.total_questions - category.unanswered_questions) * (category.correct_percentage / 100);
        }
      });
      return acc;
    }, { totalQuestions: 0, unansweredQuestions: 0, correctAnswers: 0 });
  }, [categoriesData]);

  const overallCorrectPercentage = useMemo(() => {
    return totals.totalQuestions > 0
      ? (totals.correctAnswers / (totals.totalQuestions - totals.unansweredQuestions)) * 100
      : 0;
  }, [totals]);

  const calculateConfidenceCounts = useCallback((questionsData: QuizHistory['questions_data']) => {
    return questionsData.reduce((counts, q) => {
      if (q.answered && q.confidenceLevel) {
        counts[q.confidenceLevel as keyof typeof counts] = (counts[q.confidenceLevel as keyof typeof counts] || 0) + 1;
      }
      return counts;
    }, { confident: 0, maybe: 0, guessing: 0 });
  }, []);

  // Updated handleScroll function
  const handleScroll = useCallback(() => {
    if (tableRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tableRef.current;
      const isAtEnd = scrollLeft >= scrollWidth - clientWidth - 10; // Changed from 1 to 10
      setShowScrollIndicator(!isAtEnd);
    }
  }, []);

  const getFilterCountsFromQuizHistory = useCallback((quizHistory: QuizHistory): FilterCounts => {
    const confidenceCounts = calculateConfidenceCounts(quizHistory.questions_data);
    return {
      all: quizHistory.total_questions,
      correct: quizHistory.correct_answers,
      incorrect: quizHistory.incorrect_answers,
      confident: confidenceCounts.confident,
      maybe: confidenceCounts.maybe,
      guessing: confidenceCounts.guessing
    };
  }, [calculateConfidenceCounts]);

  const getFilterCountsFromQuizSummary = useCallback((summary: QuizSummary[]): FilterCounts => {
    console.log('Getting filter counts from quiz summary:', summary);
    const counts: FilterCounts = {
      all: 0,
      correct: 0,
      incorrect: 0,
      confident: 0,
      maybe: 0,
      guessing: 0
    };
  
    summary.forEach(q => {
      counts.all++;
      counts[q.isCorrect ? 'correct' : 'incorrect']++;
      if (q.confidenceLevel && q.confidenceLevel !== 'Not recorded') {
        const confidenceKey = q.confidenceLevel.toLowerCase() as keyof FilterCounts;
        if (confidenceKey in counts) {
          counts[confidenceKey]++;
        } else {
          console.warn(`Unknown confidence level: ${q.confidenceLevel} for question ${q.questionId}`);
        }
      } else {
        console.warn(`Question ${q.questionId} has no confidence level recorded. Full question data:`, q);
      }
    });
    
    console.log('Calculated filter counts:', counts);
    return counts;
  }, []);


  const filterCountsRef = useRef<FilterCounts>({
    all: 0,
    correct: 0,
    incorrect: 0,
    confident: 0,
    maybe: 0,
    guessing: 0
  });

  
    // This function updates the filter counts based on quiz history
    const updateFilterCounts = useCallback((data: QuizHistory | QuizHistory[] | QuizSummary[]) => {
      if (!data) {
        console.warn('Attempted to update filter counts with empty or undefined data');
        return;
      }

      let newFilterCounts: FilterCounts;

      if (Array.isArray(data)) {
        if (data.length === 0) {
          console.warn('Attempted to update filter counts with empty array');
          return;
        }
        if ('parentCategory' in data[0]) {
          console.log('Updating filter counts from QuizSummary[]');
          newFilterCounts = getFilterCountsFromQuizSummary(data as QuizSummary[]);
        } else {
          console.log('Updating filter counts from QuizHistory[]');
          newFilterCounts = getFilterCountsFromQuizHistory(data[0]);
        }
      } else {
        console.log('Updating filter counts from single QuizHistory');
        newFilterCounts = getFilterCountsFromQuizHistory(data);
      }

      if (JSON.stringify(newFilterCounts) !== JSON.stringify(filterCountsRef.current)) {
        filterCountsRef.current = newFilterCounts;
        setFilterCounts(newFilterCounts);
        console.log('Filter counts updated:', newFilterCounts);
      } else {
        console.log('Filter counts unchanged, skipping update');
      }
    }, [getFilterCountsFromQuizHistory, getFilterCountsFromQuizSummary]);

    const fetchQuizSummary = useCallback(async (quizId: string) => {
      setIsLoadingQuizSummary(true);
      setError(null);
      try {
        const quizHistoryData = await api.getQuizHistoryById(quizId);
        if (!quizHistoryData) throw new Error('Quiz history not found');
    
        const userId = currentUser?.id || '';
        const quizQuestions = await api.getQuestionsById(
          quizHistoryData.questions_data.map((q: QuizQuestionData) => q.questionId),
          userId,
          courseId
        );
    
        const summary = await Promise.all(quizHistoryData.questions_data.map(async (questionData: QuizQuestionData) => {
          const question = quizQuestions.find(q => q.id === questionData.questionId);
          
          if (!question) {
            console.warn(`Question with id ${questionData.questionId} not found`);
            return null;
          }
    
          const categoryData = question.question_category_id 
            ? await api.getQuestionCategory(question.question_category_id)
            : null;
          
          const { questionText, options } = parseQuestionContent(question.question_content || '');
    
          return {
            parentCategory: categoryData?.parent_question_category_name || '',
            subCategory: categoryData?.sub_question_category_name || '',
            questionId: questionData.questionId,
            question: questionText,
            options: options,
            userAnswer: questionData.userAnswer,
            correctAnswer: question.correct_answer || '',
            answerContent: question.answer_content || '',
            isCorrect: questionData.isCorrect,
            note: question.note,
            confidenceLevel: questionData.confidenceLevel || 'Not recorded',
            timeSpent: questionData.timeSpent || 0
          };
        }));
    
        // Filter out any null values from the summary
        const validSummary = summary.filter(item => item !== null);
    
        setQuizSummary(validSummary);
        console.log('Updating filter counts from fetchQuizSummary');
        updateFilterCounts(validSummary);
        setViewingCompletedQuiz(true);
      } catch (err) {
        console.error('Failed to load quiz results:', err);
        setError('Failed to load quiz results. Please try again.');
      } finally {
        setIsLoadingQuizSummary(false);
      }
    }, [courseId, currentUser, updateFilterCounts, api]);


    const updateElapsedTime = useCallback(() => {
      if (quizStartTimeRef.current && !isPaused && !showExplanation) {
        const now = Date.now();
        const newElapsedTime = Math.floor((now - quizStartTimeRef.current - pausedTimeRef.current) / 1000);
        setElapsedTime(newElapsedTime);
      }
    }, [isPaused, showExplanation]);
  
    useEffect(() => {
      if (quizStartTime && !isPaused && !showExplanation) {
        quizStartTimeRef.current = quizStartTime;
        pausedTimeRef.current = pausedTime;
        timerRef.current = setInterval(updateElapsedTime, 1000);
      } else if (timerRef.current) {
        clearInterval(timerRef.current);
      }
  
      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }, [quizStartTime, isPaused, showExplanation, pausedTime, updateElapsedTime]);

    //END TIMER

// USE EFFECTS SECTION

useEffect(() => {
  if (user) {
    setCurrentUser(user);
  }
}, [user]);

// Main useEffect for data fetching and initialization
useEffect(() => {
  if (quizHistory && quizHistory.length > 0) {
    const latestQuiz = quizHistory[0];
    const confidenceCounts = calculateConfidenceCounts(latestQuiz.questions_data);
    setFilterCounts({
      all: latestQuiz.total_questions,
      correct: latestQuiz.correct_answers,
      incorrect: latestQuiz.incorrect_answers,
      confident: confidenceCounts.confident,
      maybe: confidenceCounts.maybe,
      guessing: confidenceCounts.guessing
    });
  }
}, [quizHistory, calculateConfidenceCounts]);

// Handle available question counts
useEffect(() => {
  if (availableQuestionCounts) {
    if (keslerQFilter.includes('unanswered')) {
      setCustomQuestionCount(availableQuestionCounts.unanswered || 0);
    } else {
      const totalAvailable = keslerQFilter.reduce((sum, filter) => sum + (availableQuestionCounts[filter] || 0), 0);
      setCustomQuestionCount(totalAvailable);
    }
  }
}, [availableQuestionCounts, keslerQFilter]);

  // Add this effect to handle pausing when showing explanation
  useEffect(() => {
    if (showExplanation) {
      setExplanationStartTime(Date.now());
    } else if (explanationStartTime) {
      setPausedTime(prev => prev + (Date.now() - explanationStartTime));
      setExplanationStartTime(null);
    }
  }, [showExplanation]);

  // Add this effect to handle pausing when user navigates away
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (quizStarted && !quizCompleted) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden && quizStarted && !quizCompleted) {
        handlePauseQuiz();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [quizStarted, quizCompleted]);

//LOG TESTS REMOVE LATER

useEffect(() => {
  if (availableQuestionCounts) {
    console.log('availableQuestionCounts:', availableQuestionCounts);
  }
}, [availableQuestionCounts]);

// Handle quiz question choices
useEffect(() => {
  const choiceElements = document.querySelectorAll('.quiz-content [data-choice]');
  choiceElements.forEach(element => {
    element.addEventListener('click', (e) => {
      const choice = element.getAttribute('data-choice');
      if (choice) {
        handleAnswer(choice);
      }
    });
  });

  return () => {
    choiceElements.forEach(element => {
      element.removeEventListener('click', () => {});
    });
  };
}, [currentQuestion]);

useEffect(() => {
  if (categoriesData) {
    setCategoriesStructure(categoriesData.structuredCategories);
    console.log('Categories data in component:', categoriesData);
  }
}, [categoriesData]);

useEffect(() => {
  const historyToUse = isFilteringLocally ? localQuizHistory : quizHistory;
  if (historyToUse && historyToUse.length > 0) {
    console.log('Updating filter counts from useEffect');
    updateFilterCounts(historyToUse);
  }
}, [isFilteringLocally, updateFilterCounts]);

useEffect(() => {
  if (quizHistory && !isFilteringLocally) {
    setLocalQuizHistory(quizHistory);
  }
}, [quizHistory, isFilteringLocally]);

useEffect(() => {
  if (activeQuiz && !isActiveQuizLoading) {
    setShowPopup(true);
  }
}, [activeQuiz, isActiveQuizLoading]);

useEffect(() => {
  if (resumeQuizId) {
    // Instead of fetching, we'll set the active quiz directly if it matches the resumeQuizId
    if (activeQuiz && activeQuiz.id === resumeQuizId) {
      setShowPopup(true);
    }
  }
}, [resumeQuizId, activeQuiz]);

useEffect(() => {
  if (categoriesData?.statsMap) {
    setLocalCategoryStats(categoriesData.statsMap);
  }
}, [categoriesData]);

  // Add this effect to reset scroll position
  useEffect(() => {
    scrollToTop();
  }, [quizStarted, quizCompleted, viewingCompletedQuiz, currentQuestion, scrollToTop]);

  useEffect(() => {
    if (closingSummary) {
      const timer = setTimeout(() => {
        setClosingSummary(false);
      }, 100); // Small delay to ensure the view has changed
      return () => clearTimeout(timer);
    }
  }, [closingSummary]);

  // Update categoryStats when cachedCategoryStats changes
  useEffect(() => {
    if (cachedCategoryStats) {
      const formattedStats = cachedCategoryStats.reduce((acc: { [key: string]: CategoryStats }, stat: CategoryStats) => {
        acc[stat.category_id] = stat;
        return acc;
      }, {});
      setCategoryStats(formattedStats);
    }
  }, [cachedCategoryStats]);

  useEffect(() => {
    const categoryFromUrl = searchParams.get('category');
    const countFromUrl = searchParams.get('count');

    if (categoryFromUrl) {
      setSelectedCategories([categoryFromUrl]);
      setShowCustomizer(true);
    }

    if (countFromUrl) {
      const parsedCount = parseInt(countFromUrl, 10);
      if (!isNaN(parsedCount)) {
        setCustomQuestionCount(parsedCount);
      }
    }

    // Always show the customizer when there are URL parameters
    if (categoryFromUrl || countFromUrl) {
      setShowCustomizer(true);
    }
  }, [searchParams]);

  // Updated useEffect for scroll handling
  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener('scroll', handleScroll);
      handleScroll(); // Check initial scroll position
    }
    return () => {
      if (tableElement) {
        tableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

// END USE EFFECT



// This function handles the selection of categories for the quiz
const handleCategorySelection = useCallback((categoryId: string, isParent: boolean) => {
  setSelectedCategories(prev => {
    let newSelection: string[];
    if (isParent) {
      const parentCategory = categoriesData?.structuredCategories[categoryId];
      if (parentCategory) {
        const subCategoryIds = parentCategory
          .filter(cat => cat.sub_question_category_name !== 'All')
          .map(cat => cat.id);
        const isAllSelected = subCategoryIds.every(id => prev.includes(id));
        if (isAllSelected) {
          newSelection = prev.filter(id => !subCategoryIds.includes(id));
        } else {
          newSelection = [...new Set([...prev, ...subCategoryIds])];
        }
      } else {
        newSelection = prev;
      }
    } else {
      newSelection = prev.includes(categoryId)
        ? prev.filter(id => id !== categoryId)
        : [...prev, categoryId];
    }
    
    // Update selectAll state
    const allCategoryIds = Object.values(categoriesData?.structuredCategories || {})
      .flatMap(subCategories => subCategories
        .filter(category => category.sub_question_category_name !== 'All')
        .map(category => category.id)
      );
    setSelectAll(newSelection.length === allCategoryIds.length);
    
    // Show customizer if categories are selected
    setShowCustomizer(newSelection.length > 0);
    
    return newSelection;
  });
}, [categoriesData]);


  
// This function formats time in seconds to a string format (minutes:seconds)
const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

  // Helper function to ensure valid confidence level
  const getValidConfidenceLevel = (level: string | null): QuizQuestionData['confidenceLevel'] | null => {
    if (level === 'confident' || level === 'maybe' || level === 'guessing') {
      return level;
    }
    return null;  // Return null if the level is not valid or not set
  };




  const handleStartQuiz = useCallback(async () => {
    if (!currentUser) return;
  
    if (activeQuizRef.current) {
      setShowPopup(true);
      return;
    }
  
    try {
      console.log('Starting custom quiz with params:', {
        courseId,
        selectedCategories,
        keslerQFilter,
        customQuestionCount,
        userId: currentUser.id
      });
  
      // Calculate the limited question count
      const MAX_ALLOWED_QUESTIONS = 72; // Make sure this constant is defined at the top of your component or imported
      const limitedQuestionCount = Math.min(customQuestionCount, MAX_ALLOWED_QUESTIONS);
  
      // Fetch questions without resetting
      const customQuestions = await api.getQuestionsForCustomQuiz(
        courseId,
        selectedCategories,
        keslerQFilter,
        limitedQuestionCount,
        currentUser.id
      );
  
      console.log('Received custom questions:', customQuestions);
  
      if (!customQuestions || customQuestions.length === 0) {
        setError('No questions were returned for the selected criteria.');
        return;
      }
  
      setCurrentView('quiz');
      setQuestions(customQuestions);
      setCurrentQuestion(0);
      setUserAnswers({});
      setQuizCompleted(false);
      setQuizStarted(true);
      setNotes({});
  
      // Set initial confidence levels based on history
      const initialConfidenceLevels = customQuestions.reduce((acc, question) => {
        acc[question.id] = question.confidenceLevel || null;
        return acc;
      }, {} as {[questionId: string]: string | null});
      
      setConfidenceHistory(initialConfidenceLevels);
  
      const startTime = Date.now();
      setQuizStartTime(startTime);
      setQuestionStartTime(startTime);
      setElapsedTime(0);
      setPausedTime(0);
      setIsPaused(false);
  
      // Reset filter counts
      setFilterCounts({
        all: 0,
        correct: 0,
        incorrect: 0,
        confident: 0,
        maybe: 0,
        guessing: 0
      });
  
      const quizHistoryId = await api.createQuizHistory(currentUser.id, courseId, customQuestions.map(q => q.id));
      setCurrentQuizHistoryId(quizHistoryId);
  
      const newActiveQuiz: QuizHistory = {
        id: quizHistoryId,
        start_time: new Date(startTime).toISOString(),
        end_time: null,
        total_questions: customQuestions.length,
        correct_answers: 0,
        incorrect_answers: 0,
        percentage_correct: 0,
        completed: false,
        questions_data: customQuestions.map(q => ({
          questionId: q.id,
          answered: false,
          isCorrect: false,
          timeSpent: 0,
          userAnswer: '',
          confidenceLevel: getValidConfidenceLevel(q.confidenceLevel)
        })),
        total_time: 0,
        avg_time_per_question: 0
      };
      
      activeQuizRef.current = newActiveQuiz;
      setActiveQuiz(newActiveQuiz);
  
      // Clear the URL parameters after starting the quiz
      navigate(`/course/${courseId}/quiz`, { replace: true });
  
    } catch (err) {
      console.error('Failed to start custom quiz:', err);
      setError(`Failed to start custom quiz. Please try again. Error: ${err instanceof Error ? err.message : JSON.stringify(err)}`);
    }
  }, [courseId, selectedCategories, keslerQFilter, customQuestionCount, currentUser, setError, setActiveQuiz]);

  const handlePauseQuiz = useCallback(() => {
    if (!isPaused) {
      setIsPaused(true);
      const newPausedTime = pausedTime + (Date.now() - (quizStartTime || 0) - pausedTime);
      setPausedTime(newPausedTime);
      pausedTimeRef.current = newPausedTime;
    }
  }, [isPaused, quizStartTime, pausedTime]);


  useEffect(() => {
    const initializeQuiz = async () => {
      setIsLoading(true);
      if (user) {
        setCurrentUser(user);
        setIsLoading(false);
      }
    };

    initializeQuiz();
  }, [user]);
  
  // This function handles resuming a previously started quiz
  const handleResumeQuiz = useCallback(async (quizToResume: QuizHistory) => {
    setIsResumingQuiz(true);
    try {
      console.log('Attempting to resume quiz:', quizToResume);
      
      if (!quizToResume) {
        throw new Error('Quiz data is null or undefined');
      }
      
      if (!quizToResume.questions_data || quizToResume.questions_data.length === 0) {
        throw new Error('Quiz has no questions data');
      }
      
      if (!currentUser) {
        throw new Error('Current user is not set');
      }

    const questionIds = quizToResume.questions_data.map((q: QuizQuestionData) => q.questionId);
    console.log('Question IDs to fetch:', questionIds);

    const quizQuestions = await api.getQuestionsById(questionIds, currentUser.id, courseId);
    console.log('Fetched quiz questions:', quizQuestions);

    if (quizQuestions.length === 0) {
      throw new Error('Failed to fetch any quiz questions');
    }

    setQuestions(quizQuestions);
    const answeredQuestionsCount = quizToResume.questions_data.filter((q) => q.answered).length;
    console.log('Number of answered questions:', answeredQuestionsCount);
    setCurrentQuestion(answeredQuestionsCount);

    const userAnswersObj = quizToResume.questions_data.reduce((acc, q) => {
      if (q.answered) {
        acc[q.questionId] = q.userAnswer;
      }
      return acc;
    }, {} as Record<string, string>);
    console.log('User answers:', userAnswersObj);
    setUserAnswers(userAnswersObj);

    setCurrentQuizHistoryId(quizToResume.id);
    setQuizCompleted(false);
    
    const now = Date.now();
    const originalStartTime = new Date(quizToResume.start_time).getTime();
    const pausedDuration = now - originalStartTime - (quizToResume.total_time * 1000);

    setQuizStartTime(originalStartTime);
    quizStartTimeRef.current = originalStartTime;
    setQuestionStartTime(now);
    setElapsedTime(quizToResume.total_time);
    setPausedTime(pausedDuration);
    pausedTimeRef.current = pausedDuration;
    setIsPaused(false);
    
    setQuizStarted(true);
    setCurrentView('quiz');
    console.log('Quiz resumed successfully');
  } catch (err) {
    console.error('Failed to prepare quiz for resumption:', err);
  } finally {
    setIsResumingQuiz(false);
  }
}, [setQuestions, setCurrentQuestion, setUserAnswers, setCurrentQuizHistoryId, setQuizCompleted, setQuizStarted, setError, currentUser, courseId]);

useEffect(() => {
  if (isLoading) return; // Don't proceed if still loading

  if (viewQuizId && from === 'analytics') {
    fetchQuizSummary(viewQuizId);
    setViewingCompletedQuiz(true);
    setShowSummary(true);
    setQuizCompleted(true);
  } else if (resumeQuizId && quizData && !viewingCompletedQuiz && currentUser) {
    handleResumeQuiz(quizData);
  }
}, [isLoading, viewQuizId, resumeQuizId, quizData, from, viewingCompletedQuiz, fetchQuizSummary, handleResumeQuiz, currentUser]);

//QUIZ SUMMARY DETAILS AND FILTERING

// This function handles viewing the results of a completed quiz
const handleViewQuizResults = async (quizId: string) => {
  try {
    if (!currentUser) throw new Error('User not authenticated');

    // Fetch the specific quiz history
    const quizHistoryData = await api.getQuizHistoryById(quizId);
    if (!quizHistoryData) throw new Error('Quiz history not found');

    // Fetch all questions for this quiz
    const quizQuestions = await api.getQuestionsById(
      quizHistoryData.questions_data.map((q: QuizQuestionData) => q.questionId),
      currentUser.id,
      courseId
    );

      // Create quiz summary
      const summary = await Promise.all(quizHistoryData.questions_data.map(async (questionData: QuizQuestionData) => {
        const question = quizQuestions.find(q => q.id === questionData.questionId);
        const categoryData = question?.question_category_id 
          ? await api.getQuestionCategory(question.question_category_id)
          : null;
        const { questionText, options } = parseQuestionContent(question?.question_content || '');
        return {
          parentCategory: categoryData?.parent_question_category_name || '',
          subCategory: categoryData?.sub_question_category_name || '',
          questionId: questionData.questionId,
          question: questionText,
          options: options,
          userAnswer: questionData.userAnswer,
          correctAnswer: question?.correct_answer || '',
          answerContent: question?.answer_content || '',
          isCorrect: questionData.isCorrect,
          note: question?.note || null,
          confidenceLevel: questionData.confidenceLevel || 'Not recorded',
          timeSpent: questionData.timeSpent || 0
        };
      }));

      setCurrentQuizSummary(summary);
      setQuizSummary(summary);
      setQuizCompleted(true);
      setQuizStarted(true);
      setViewingCompletedQuiz(true);

      // Update the filter counts with this quiz
      console.log('Updating filter counts from handleViewQuizResults');
      updateFilterCounts([quizHistoryData]);

      // Calculate and update total time and average time
      const totalTimeSpent = summary.reduce((sum, item) => sum + (item.timeSpent || 0), 0);
      const avgTimePerQuestion = summary.length > 0 ? totalTimeSpent / summary.length : 0;
      setTotalTime(totalTimeSpent);
      setAverageTime(avgTimePerQuestion);

    } catch (err) {
      console.error('Failed to load quiz results:', err);
      setError('Failed to load quiz results. Please try again.');
    }
  };

  
  // Update handleEndQuizSession to accept a quizId parameter
  const handleEndQuizSession = useCallback(async (quizId: string) => {
    setIsEndingQuiz(true);
    try {
      await api.endQuizSession(quizId, totalTime);
      activeQuizRef.current = null;
      setActiveQuiz(null);
      setShowPopup(false);

      // Invalidate category stats to trigger a refetch
      invalidateCategoryStats();
      
      // Fetch updated quiz history
      if (currentUser) {
        await refetchQuizHistory();
      }
      
      // Reset quiz state
      setQuestions([]);
      setCurrentQuestion(0);
      setUserAnswers({});
      setQuizCompleted(true);
      setQuizStarted(false);
  
      // Fetch the ended quiz data and update quiz summary
      await handleViewQuizResults(quizId);
  
      // Refetch quiz history again to ensure latest data
      await refetchQuizHistory();
      
    } catch (err) {
      console.error('Failed to end quiz session:', err);
      setError('Failed to end quiz session. Please try again.');
    } finally {
      setIsEndingQuiz(false);
    }
  }, [currentUser, refetchQuizHistory, handleViewQuizResults, setQuestions, setCurrentQuestion, setUserAnswers, setQuizCompleted, setQuizStarted, setError, invalidateCategoryStats]);

  const filteredQuizHistory = useMemo(() => {
    if (!quizHistory) return [];
    return quizHistory.filter(quiz => {
      if (summaryFilter === 'all') return true;
      if (summaryFilter === 'correct') return quiz.correct_answers > 0;
      if (summaryFilter === 'incorrect') return quiz.incorrect_answers > 0;
      const confidenceCounts = calculateConfidenceCounts(quiz.questions_data);
      return confidenceCounts[summaryFilter as ConfidenceLevel] > 0;
    });
  }, [quizHistory, summaryFilter, calculateConfidenceCounts]);

  const handleSummaryFilterChange = useCallback((newFilter: string) => {
    console.log('Changing filter locally to:', newFilter);
    setSummaryFilter(newFilter);
  }, []);

  //Quiz History

  // Modify the existing handleAnswer function
  const handleAnswer = useCallback((answerId: string) => {
    setSelectedAnswer(answerId);
    
    const choiceElements = document.querySelectorAll('.quiz-content [data-choice]');
    choiceElements.forEach(element => {
      const choice = element.getAttribute('data-choice');
      if (choice === answerId) {
        element.classList.add('selected');
        element.closest('li')?.classList.add('selected');
      } else {
        element.classList.remove('selected');
        element.closest('li')?.classList.remove('selected');
      }
    });
  }, []);

  // Modify the existing handleConfidenceSelection function
  const handleConfidenceSelection = useCallback((level: ConfidenceLevel) => {
    setConfidenceLevel(level);
    setFocusedConfidenceLevel(confidenceLevels.indexOf(level));
  }, [confidenceLevels]);

  const handleSubmitAnswer = async () => {
    setIsSubmitting(true);
    if (selectedAnswer && currentUser && confidenceLevel && currentQuizHistoryId && questionStartTime) {
      const question = questions[currentQuestion];
      const answerLetter = selectedAnswer.charAt(0);
      const isCorrect = answerLetter === question.correct_answer;
      const currentTime = Date.now();
      const timeSpent = Math.max(0, Math.floor((currentTime - questionStartTime) / 1000)); // Ensure non-negative
    
      try {
        // Save the question response
        await api.saveQuestionResponse({
          user_id: currentUser.id,
          question_id: question.id,
          course_id: courseId,
          selected_answer: answerLetter,
          is_correct: isCorrect,
          confidence_level: confidenceLevel,
          response_time: timeSpent
        });
  
        // Update question confidence level
        await api.updateQuestionConfidenceLevel(currentUser.id, courseId, question.id, confidenceLevel);
  
        // Update quiz history
        await api.updateQuizHistory(currentQuizHistoryId, question.id, selectedAnswer, isCorrect, confidenceLevel, timeSpent);
  
        // Update confidenceHistory
        setConfidenceHistory(prev => ({...prev, [question.id]: confidenceLevel}));
  
        setUserAnswers({ ...userAnswers, [question.id]: selectedAnswer });
        

  
        const { questionText, tableHTML, options, romanList, isTableQuestion } = parseQuestionContent(question.question_content);
  
        const newQuizSummaryItem: QuizSummary = {
          parentCategory: question.question_category_name,
          subCategory: question.sub_question_category_name,
          questionId: question.id,
          question: questionText,
          romanList,
          options,
          tableHTML,
          isTableQuestion,
          userAnswer: selectedAnswer,
          correctAnswer: question.correct_answer,
          answerContent: question.answer_content,
          isCorrect,
          note: question.note,
          confidenceLevel,
          timeSpent
        };
  
        console.log('New quiz summary item:', newQuizSummaryItem);
  
        setQuizSummary(prevSummary => [...prevSummary, newQuizSummaryItem]);
  
        // Update local category stats
        updateLocalCategoryStats(question, isCorrect, confidenceLevel);
  
        // Update filter counts
        updateFilterCounts([newQuizSummaryItem]);
  
        setConfidenceLevel(null);
  
        // Highlight the selected and correct answers
        highlightAnswers(selectedAnswer, question.correct_answer);
  
        // Close calculator and spreadsheet
        setShowCalculator(false);
        setShowSpreadsheet(false);

        // Update based on test mode
        if (isTestMode) {
          await handleNextQuestion();
        } else {
          setShowExplanation(true);
        }

      } catch (err) {
        console.error('Error saving answer:', err);
        setError('Failed to save answer. Please try again.');
      }
    }
    setIsSubmitting(false);
  };

    // This function updates the filter counts
    const setFilterCountsCallback = useCallback((newCounts: typeof filterCounts | ((prevCounts: typeof filterCounts) => typeof filterCounts)) => {
      setFilterCounts(newCounts);
      console.log('Filter counts updated (setFilterCountsCallback):', newCounts);
    }, []);

  const updateLocalCategoryStats = (question: Question, isCorrect: boolean, confidenceLevel: string) => {
    setLocalCategoryStats(prevStats => {
      const updatedStats = { ...prevStats };
      const categoryId = question.question_category_id;
      if (updatedStats[categoryId]) {
        updatedStats[categoryId].total_questions--;
        updatedStats[categoryId].unanswered_questions--;
        
        // Calculate new correct answers
        const answeredQuestions = updatedStats[categoryId].total_questions - updatedStats[categoryId].unanswered_questions;
        const correctAnswers = Math.round((updatedStats[categoryId].correct_percentage / 100) * answeredQuestions);
        const newCorrectAnswers = isCorrect ? correctAnswers + 1 : correctAnswers;
        
        // Update correct percentage
        updatedStats[categoryId].correct_percentage = (newCorrectAnswers / (answeredQuestions + 1)) * 100;
      }
      return updatedStats;
    });
  };

  const highlightAnswers = (selectedAnswer: string, correctAnswer: string) => {
    const dataCells = document.querySelectorAll('.quiz-content td [data-choice]');
    if (dataCells.length > 0) {
      dataCells.forEach(cell => {
        const cellParent = cell.closest('td');
        const cellChoice = cell.getAttribute('data-choice');
        if (cellChoice === selectedAnswer) {
          cellParent?.classList.add('selected');
          cellParent?.classList.add(selectedAnswer === correctAnswer ? 'correct' : 'incorrect');
        }
        if (cellChoice === correctAnswer) {
          cellParent?.classList.add('correct');
        }
      });
    }
  };

  const handleNextQuestion = async () => {
    setIsLoadingNextQuestion(true);
    try {
      setShowExplanation(false);
      setConfidenceLevel(null);
      setSelectedAnswer(null);
      setPausedTime(prev => prev + (explanationStartTime ? (Date.now() - explanationStartTime) : 0));
      setExplanationStartTime(null);
      setQuestionStartTime(Date.now());
  
      // Update this in the handleNextQuestion function
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
        setIsLastQuestion(currentQuestion + 1 === questions.length - 1);
      } else {
        setIsLastQuestion(true);
        await handleFinishQuiz();
      }

    } catch (error) {
      console.error('Error loading next question:', error);
      setError('Failed to load next question. Please try again.');
    } finally {
      setIsLoadingNextQuestion(false);
    }
  };

  // Update the handleFinishQuiz function
  const handleFinishQuiz = async () => {
    setIsFinishingQuiz(true);
    try {
      await handleQuizComplete();
      setQuizCompleted(true);
      // Invalidate and refetch categories and available question counts
      queryClient.invalidateQueries(['categories', courseId]);
      queryClient.invalidateQueries(['availableQuestionCounts', courseId]);
    } catch (error) {
      console.error('Error finishing quiz:', error);
      setError('Failed to finish quiz. Please try again.');
    } finally {
      setIsFinishingQuiz(false);
    }
  };


  //NOTE TAKING SECTION

  const sanitizeInput = useCallback((input: string) => {
    return DOMPurify.sanitize(input);
  }, []);

  // Function to handle note content changes
  const handleNoteChange = useCallback((indexOrValue: number | string, value?: string) => {
    if (typeof indexOrValue === 'number' && value !== undefined) {
      // This is for summary notes
      const sanitizedContent = sanitizeInput(value);
      setNoteContents(prev => ({
        ...prev,
        [indexOrValue]: sanitizedContent.slice(0, MAX_NOTE_LENGTH)
      }));
    } else if (typeof indexOrValue === 'string') {
      // This is for the main quiz note
      const sanitizedContent = sanitizeInput(indexOrValue);
      setNoteContent(sanitizedContent.slice(0, MAX_NOTE_LENGTH));
    }
  }, [sanitizeInput]);

  const handleNoteTaking = async () => {
    if (!currentUser) return;
    try {
      const note = await api.getNoteForQuestion(currentUser.id, courseId, questions[currentQuestion].id);
      if (note) {
        const sanitizedContent = sanitizeInput(note.content);
        setNote(sanitizedContent);
        setCurrentNote({
          ...note,
          content: sanitizedContent
        });
      } else {
        setNote('');
        setCurrentNote(null);
      }
      setShowNoteEditor(true);
    } catch (err) {
      console.error('Failed to fetch note:', err);
    }
  };
  
  const handleUpdateNote = async () => {
    console.log('handleUpdateNote called');
    if (!currentUser) {
      console.error('No current user');
      setError('You must be logged in to update notes.');
      return;
    }
    if (!questions.length || currentQuestion >= questions.length) {
      console.error('Invalid question index');
      setError('Unable to update note: Invalid question selected.');
      return;
    }
    
    const currentQuestionData = questions[currentQuestion];
    console.log('Current question data:', currentQuestionData);
  
    if (!currentQuestionData) {
      console.error('Current question data is null');
      setError('Failed to update/create note: Question data is missing.');
      return;
    }
  
    try {
      console.log('Attempting to update/create note');
      const sanitizedContent = sanitizeInput(noteContent);
      const updatedNote = await api.updateNote(
        currentQuestionData.note?.id || null,
        { content: sanitizedContent },
        currentUser.id,
        courseId,
        currentQuestionData.id,
        null, // lessonId is null for question notes
        null // moduleId is null for question notes
      );
      
      console.log('Note updated/created successfully:', updatedNote);
  
      // Update the questions state with the updated or new note
      setQuestions(prevQuestions => prevQuestions.map(q => 
        q.id === currentQuestionData.id ? { ...q, note: updatedNote } : q
      ));
  
      setIsEditingNote(false);
      setNoteContent('');
  
      await api.logUserActivity({
        user_id: currentUser.id,
        course_id: courseId,
        activity_type: currentQuestionData.note ? 'update_note' : 'create_note',
        activity_id: updatedNote.id,
        result: 'success'
      });
  
      console.log('User activity logged');
    } catch (err: unknown) {
      console.error('Failed to update/create note:', err);
      if (err instanceof Error) {
        setError(`Failed to update/create note: ${err.message}`);
      } else {
        setError('Failed to update/create note: Unknown error');
      }
    }
  };
  

  const handleSaveNote = async () => {
    if (!currentUser) return;
    try {
      const question = questions[currentQuestion];
      const sanitizedContent = sanitizeInput(noteContent);
      const updatedNote = await api.updateNote(
        question.note?.id || null,
        { content: sanitizedContent },
        currentUser.id,
        courseId,
        question.id,
        null, // lessonId is null for question notes
        null // moduleId is null for question notes
      );
      
      setQuestions(prevQuestions => 
        prevQuestions.map((q, index) => 
          index === currentQuestion ? { ...q, note: updatedNote } : q
        )
      );
      setNoteContent('');
      setIsEditingNote(false);
  
      await api.logUserActivity({
        user_id: currentUser.id,
        course_id: courseId,
        activity_type: question.note ? 'update_note' : 'create_note',
        activity_id: updatedNote.id,
        result: 'success'
      });
    } catch (err) {
      console.error('Failed to save note:', err);
      setError('Failed to save note. Please try again.');
    }
  };
  

  const handleClearNote = async () => {
    if (!currentUser || !questions.length || currentQuestion >= questions.length) return;
    const currentQuestionData = questions[currentQuestion];
    if (!currentQuestionData.note) return;
  
    try {
      await api.deleteNote(currentQuestionData.note.id);
      
      // Update the questions state to remove the note
      setQuestions(prevQuestions => prevQuestions.map(q => 
        q.id === currentQuestionData.id ? { ...q, note: null } : q
      ));
  
      setNoteContent('');
  
      await api.logUserActivity({
        user_id: currentUser.id,
        course_id: courseId,
        activity_type: 'delete_note',
        activity_id: currentQuestionData.note.id,
        result: 'success'
      });
    } catch (err) {
      console.error('Failed to clear note:', err);
      setError('Failed to clear note. Please try again.');
    }
  };

//END QUIZ NOTE FUNCTIONS

//QUIZ TOOLS

const handleEndTestlet = useCallback(async () => {
  if (currentQuizHistoryId && currentUser) {
    try {
      await api.endQuizSession(currentQuizHistoryId, elapsedTime);
      setQuizCompleted(true);
      setShowEndTestletModal(false);
      setQuizStarted(false);
      
      // Invalidate and refetch necessary data
      await refetchQuizHistory();
      queryClient.invalidateQueries(['availableQuestionCounts', courseId, currentUser.id]);
      
      // Navigate to the quiz summary
      navigate(`/course/${courseId}/quiz`, { 
        state: { 
          viewQuizId: currentQuizHistoryId,
          from: 'quiz'
        },
        replace: true
      });
    } catch (err) {
      console.error('Failed to end testlet session:', err);
      setError('Failed to end testlet session. Please try again.');
    }
  }
}, [currentQuizHistoryId, currentUser, elapsedTime, courseId, navigate, refetchQuizHistory, queryClient]);

const toggleCalculator = useCallback(() => {
  setShowCalculator(prev => !prev);
}, []);

const toggleSpreadsheet = useCallback(() => {
  setShowSpreadsheet(prev => !prev);
}, []);

const toggleFeedback = useCallback(() => {
  setShowFeedback(prev => !prev);
}, []);

const toggleTestMode = useCallback(() => {
  setIsTestMode(prev => !prev);
}, []);

//END QUIZ TOOLS


  //COMPLETED QUIZ

  const handleQuizComplete = useCallback(async () => {
    if (currentQuizHistoryId && currentUser) {
      setIsFinishing(true);
      try {
        // Fetch the current quiz history
        const quizHistoryData = await api.getQuizHistoryById(currentQuizHistoryId);
        if (!quizHistoryData) throw new Error('Quiz history not found');
  
        // Fetch all questions for this quiz, including notes
        const quizQuestions = await api.getQuestionsById(
          quizHistoryData.questions_data.map((q: QuizQuestionData) => q.questionId),
          currentUser.id,
          courseId
        );

        // Fetch the confidence levels for all questions
        const confidenceLevels = await api.getConfidenceLevels(currentUser.id, courseId, quizQuestions.map(q => q.id));
  
        // Create a map of question IDs to their confidence level
        const confidenceLevelMap = confidenceLevels.reduce((map, cl) => {
          map[cl.question_id] = cl.current_confidence_level;
          return map;
        }, {} as Record<string, string | null>);
  
        // Calculate total time including current elapsed time and any ongoing explanation time
        const totalTime = elapsedTime + (explanationStartTime ? Math.floor((Date.now() - explanationStartTime) / 1000) : 0);
  
        // Create updated summary
        const updatedSummary: QuizSummary[] = await Promise.all(quizHistoryData.questions_data.map(async (questionData: QuizQuestionData) => {
          // Find the corresponding question from the fetched questions
          const question = quizQuestions.find(q => q.id === questionData.questionId);
          
          // If question is not found, we'll use default values
          if (!question) {
            console.warn(`Question with id ${questionData.questionId} not found`);
            return {
              parentCategory: '',
              subCategory: '',
              questionId: questionData.questionId,
              question: 'Question not found',
              options: [],
              userAnswer: questionData.userAnswer,
              correctAnswer: '',
              answerContent: '',
              isCorrect: questionData.isCorrect,
              note: null,
              confidenceLevel: confidenceLevelMap[questionData.questionId] || 'Not recorded',
              timeSpent: questionData.timeSpent,
              romanList: '',
              tableHTML: '',
              isTableQuestion: false
            };
          }

          const categoryData = question.question_category_id 
            ? await api.getQuestionCategory(question.question_category_id)
            : null;
          
          // Parse the question content to extract all necessary properties
          const { questionText, options, romanList, tableHTML, isTableQuestion } = parseQuestionContent(question?.question_content || '');
          
          // Construct the QuizSummary object with all required properties
          return {
            parentCategory: categoryData?.parent_question_category_name || '',
            subCategory: categoryData?.sub_question_category_name || '',
            questionId: questionData.questionId,
            question: questionText,
            options: options,
            userAnswer: questionData.userAnswer,
            correctAnswer: question?.correct_answer || '',
            answerContent: question?.answer_content || '',
            isCorrect: questionData.isCorrect,
            note: question.note, // Use the note that was fetched with the question
            confidenceLevel: confidenceLevelMap[questionData.questionId] || 'Not recorded',
            timeSpent: questionData.timeSpent,
            romanList: romanList,
            tableHTML: tableHTML,
            isTableQuestion: isTableQuestion
          };
        }));
  
        // Set the quiz summary with the updated and complete data
        setQuizSummary(updatedSummary);
  
        setShowSummary(true);
        setCurrentView('summary');
  
        // End the quiz session with the calculated total time
        await api.endQuizSession(currentQuizHistoryId, totalTime);
  
        // Invalidate category stats to trigger a refetch
        invalidateCategoryStats();
  
        // Keep the existing category stats update logic
        const updatedStatsArray = await api.getCategoryStats(currentUser.id, courseId, Object.keys(categoryStats));
        const updatedStats = updatedStatsArray.reduce((acc: { [key: string]: CategoryStats }, stat: CategoryStats) => {
          acc[stat.category_id] = stat;
          return acc;
        }, {});
  
        // Calculate new filter counts based on the updated summary
        const newFilterCounts = getFilterCountsFromQuizSummary(updatedSummary);
  
        // Update filter counts
        setFilterCountsCallback(newFilterCounts);
  
        setQuizCompleted(true);
        setViewingCompletedQuiz(true);
        setQuizStartTime(null);
  
        // Invalidate and refetch available question counts
        queryClient.invalidateQueries(['availableQuestionCounts', courseId, currentUser.id]);
  
        // Refetch the quiz history
        await refetchQuizHistory();
  
        setQuizStartTime(null);
        quizStartTimeRef.current = null;
        setQuestionStartTime(null);
        setIsPaused(false);
        setPausedTime(0);
        pausedTimeRef.current = 0;
        setExplanationStartTime(null);
  
      } catch (err) {
        console.error('Failed to complete quiz:', err);
        setError('Failed to complete quiz. Please try again.');
      } finally {
        setIsFinishing(false);
      }
    }
  }, [currentQuizHistoryId, currentUser, courseId, elapsedTime, explanationStartTime, api, setShowSummary, setCurrentView, setQuizSummary, invalidateCategoryStats, categoryStats, setFilterCountsCallback, queryClient, refetchQuizHistory, setQuizCompleted, setViewingCompletedQuiz, setQuizStartTime, setQuestionStartTime, setIsPaused, setPausedTime, setExplanationStartTime, setError, getFilterCountsFromQuizSummary]);

const handleSelectAll = () => {
setSelectAll(!selectAll);
if (!selectAll) {
  const allCategoryIds = Object.values(categoriesStructure)
    .flatMap(subCategories => subCategories
      .filter(category => category.sub_question_category_name !== 'All')
      .map(category => category.id)
    );
  setSelectedCategories(allCategoryIds);
  setShowCustomizer(true);
} else {
  setSelectedCategories([]);
  setShowCustomizer(false);
}
};

const truncateText = (text: string, maxLength: number) => {
if (text.length <= maxLength) return text;
return text.substr(0, maxLength) + '...';
};

const handleCategoryClick = (categoryId: string) => {
setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
};

//Sub-components

const PaginationControls: React.FC<{
  currentPage: number;
  totalPages: number;
  paginate: (pageNumber: number) => void;
}> = ({ currentPage, totalPages, paginate }) => {
  return (
    <div className="flex justify-center mt-4">
      {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
        <button
          key={number}
          onClick={() => paginate(number)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === number
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          {number}
        </button>
      ))}
    </div>
  );
};

  // Helper function to get the letter for a given index
  const getLetterForIndex = (index: number) => String.fromCharCode(65 + index);


  //useKEYPRESS.TS CODE
// Handle answer selection via keystroke
  const handleKeyboardAnswer = useCallback((index: number) => {
    const answerId = getLetterForIndex(index);
    handleAnswer(answerId);
  }, [handleAnswer]);

  // Handle cycling through confidence levels
  const handleCycleConfidence = useCallback(() => {
    setFocusedConfidenceLevel((prev) => {
      const next = (prev + 1) % confidenceLevels.length;
      handleConfidenceSelection(confidenceLevels[next]);
      return next;
    });
  }, [confidenceLevels, handleConfidenceSelection]);

  // Handle next question or finish quiz
  const handleNextOrFinish = useCallback(() => {
    if (isLastQuestion) {
      handleFinishQuiz();
    } else {
      handleNextQuestion();
    }
  }, [isLastQuestion, handleFinishQuiz, handleNextQuestion]);

  // Consolidated spacebar press handler
  const handleSpacebarPress = useCallback(() => {
    if (showExplanation) {
      handleNextOrFinish();
    } else if (selectedAnswer && confidenceLevel && !isSubmitting) {
      handleSubmitAnswer();
    }
  }, [showExplanation, selectedAnswer, confidenceLevel, isSubmitting, handleNextOrFinish, handleSubmitAnswer]);

  // Update the condition for enabling keyboard shortcuts
  const isKeyboardShortcutsEnabled = !isNoteTextareaFocused && !isSpreadsheetFocused && currentView === 'quiz' && quizStarted;

  // Use the updated condition in useKeyPress hooks
  useKeyPress('a', () => handleKeyboardAnswer(0), () => isKeyboardShortcutsEnabled && !showExplanation);
  useKeyPress('b', () => handleKeyboardAnswer(1), () => isKeyboardShortcutsEnabled && !showExplanation);
  useKeyPress('c', () => handleKeyboardAnswer(2), () => isKeyboardShortcutsEnabled && !showExplanation);
  useKeyPress('d', () => handleKeyboardAnswer(3), () => isKeyboardShortcutsEnabled && !showExplanation);

  // Use the useKeyPress hook for Tab key
  useKeyPress('Tab', (event?: KeyboardEvent) => {
    if (event) {
      event.preventDefault();
    }
    handleCycleConfidence();
  }, () => isKeyboardShortcutsEnabled && !showExplanation && selectedAnswer !== null);

  // Use the useKeyPress hook for Space key
  useKeyPress(' ', (event?: KeyboardEvent) => {
    if (event) {
      event.preventDefault();
    }
    handleSpacebarPress();
  }, () => isKeyboardShortcutsEnabled);


// Main Render Logic
const renderCategories = () => {
  if (categoriesLoading) {
    return <div>Loading categories...</div>;
  }

  function hasMessage(error: unknown): error is { message: string } {
    return typeof error === 'object' && error !== null && 'message' in error;
  }
  if (categoriesError) {
    if (hasMessage(categoriesError)) {
      return <div>Error loading categories: {categoriesError.message}</div>;
    } else {
      return <div>Error loading categories: Unknown error</div>;
    }
  }

  if (!categoriesData || !categoriesData.structuredCategories) {
    return <div>No categories available</div>;
  }

  return (
      <table className="w-full border-collapse min-w-max table-fixed">
        <thead>
          <tr className="bg-light-secondary dark:bg-dark-background">
            <th className="w-1/12 text-left p-2 text-light-text dark:text-dark-text">Select</th>
            <th className="w-5/12 text-left p-2 text-light-text dark:text-dark-text">Category</th>
            <th className="w-2/12 text-center p-2 text-light-text dark:text-dark-text">Total</th>
            <th className="w-2/12 text-center p-2 text-light-text dark:text-dark-text">Unanswered</th>
            <th className="w-2/12 text-center p-2 text-light-text dark:text-dark-text">Correct %</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-primary-blue font-bold text-white">
            <td className="p-2">
              <input
                type="checkbox"
                id="selectAll"
                checked={selectAll}
                onChange={handleSelectAll}
                className="w-6 h-6 text-light-primary dark:text-dark-primary"
              />
            </td>
            <td className="p-2">
              <label htmlFor="selectAll" className="cursor-pointer text-dark-text dark:text-dark-text whitespace-normal">
                {selectAll ? 'Select None' : 'Select All'}
              </label>
            </td>
            <td className="text-center p-2 text-dark-text dark:text-dark-text">{totals.totalQuestions}</td>
            <td className="text-center p-2 text-dark-text dark:text-dark-text">{totals.unansweredQuestions}</td>
            <td className="text-center p-2 text-dark-text dark:text-dark-text">{overallCorrectPercentage.toFixed(2)}%</td>
          </tr>
          {Object.entries(categoriesStructure).map(([parentCategory, subCategories]) => {
            const parentTotals = subCategories.reduce((acc, category) => {
              if (category.sub_question_category_name !== 'All') {
                acc.total += category.total_questions || 0;
                acc.unanswered += category.unanswered_questions || 0;
                acc.correct += (category.total_questions - category.unanswered_questions) * (category.correct_percentage / 100);
              }
              return acc;
            }, { total: 0, unanswered: 0, correct: 0 });

            const parentCorrectPercentage = parentTotals.total - parentTotals.unanswered > 0
              ? (parentTotals.correct / (parentTotals.total - parentTotals.unanswered)) * 100
              : 0;

            return (
              <React.Fragment key={parentCategory}>
                <tr className="bg-light-secondary dark:bg-dark-background">
                  <td className="p-2">
                    <input
                      type="checkbox"
                      id={parentCategory}
                      checked={subCategories.every(cat => 
                        cat.sub_question_category_name === 'All' || selectedCategories.includes(cat.id)
                      )}
                      onChange={() => handleCategorySelection(parentCategory, true)}
                      className="w-6 h-6 text-light-primary dark:text-dark-primary"
                    />
                  </td>
                  <td 
                    className="p-2 font-bold text-light-text dark:text-dark-text cursor-pointer" 
                    onClick={() => handleCategoryClick(parentCategory)}
                  >
                    <label htmlFor={parentCategory} className="cursor-pointer whitespace-normal break-words">
                      {expandedCategory === parentCategory 
                        ? parentCategory 
                        : truncateText(parentCategory, 40)}
                    </label>
                  </td>
                  <td className="text-center p-2 text-light-text dark:text-dark-text">{parentTotals.total}</td>
                  <td className="text-center p-2 text-light-text dark:text-dark-text">{parentTotals.unanswered}</td>
                  <td className="text-center p-2 text-light-text dark:text-dark-text">{parentCorrectPercentage.toFixed(2)}%</td>
                </tr>
                {subCategories.filter(category => category.sub_question_category_name !== 'All').map((category, index) => (
                  <tr key={category.id} className={index % 2 === 0 ? 'bg-light-background dark:bg-dark-secondaryLight' : 'bg-blue-50 dark:bg-dark-secondary'}>
                    <td className="p-2">
                      <input
                        type="checkbox"
                        id={category.id}
                        checked={selectedCategories.includes(category.id)}
                        onChange={() => handleCategorySelection(category.id, false)}
                        className="w-6 h-6 text-light-primary dark:text-dark-primary"
                      />
                    </td>
                    <td 
                      className="p-2 pl-8 text-light-text dark:text-dark-text cursor-pointer"
                      onClick={() => handleCategoryClick(category.id)}
                    >
                      <label htmlFor={category.id} className="cursor-pointer whitespace-normal break-words">
                        {expandedCategory === category.id 
                          ? category.sub_question_category_name 
                          : truncateText(category.sub_question_category_name, 40)}
                      </label>
                    </td>
                    <td className="text-center p-2 text-light-text dark:text-dark-text">{category.total_questions || 0}</td>
                    <td className="text-center p-2 text-light-text dark:text-dark-text">{category.unanswered_questions || 0}</td>
                    <td className="text-center p-2 text-light-text dark:text-dark-text">
                      {(category.correct_percentage !== undefined && category.correct_percentage !== null) 
                        ? category.correct_percentage.toFixed(2) 
                        : '0.00'}%
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
  );
};


const renderHTML = (html: string) => {
  return { __html: DOMPurify.sanitize(html) };
};

const renderQuestion = () => {
  if (!questions || questions.length === 0 || currentQuestion >= questions.length) {
    return <div>No questions available.</div>;
  }

  const question = questions[currentQuestion];
  if (!question) {
    return <div>Question not found.</div>;
  }

  const { questionText, tableHTML, options, romanList, isTableQuestion } = parseQuestionContent(question.question_content);
  const isLastQuestion = currentQuestion === questions.length - 1;

  const triggerEndTestlet = () => {
    setShowEndTestletModal(true);
  };

  return (
    <>
      <div className="bg-white dark:bg-dark-secondaryLight p-4 rounded shadow">
        {/* Mobile view */}
        <div className="flex justify-between items-center mb-4 sm:hidden">
          <TestModeToggle 
            isTestMode={isTestMode} 
            onToggle={toggleTestMode} 
            disabled={showExplanation}
          />
          <Button
            onClick={() => setShowMobileTools(!showMobileTools)}
            className="btn btn-primary flex items-center justify-center"
          >
            <Wrench className="w-5 h-5 mr-2" />
            <span>Tools</span>
          </Button>
          <Button
            onClick={triggerEndTestlet}
            className="btn bg-red-500 hover:bg-red-600 text-white font-bold flex items-center justify-center w-10 h-10"
          >
            <X className="w-5 h-5" />
          </Button>
        </div>

        {/* Desktop view */}
        <div className="hidden sm:flex sm:flex-row justify-between items-center mb-4 pb-4 border-b border-gray-300 dark:border-gray-700">
          <div>
            <TestModeToggle 
              isTestMode={isTestMode} 
              onToggle={toggleTestMode} 
              disabled={showExplanation}
            />
          </div>
          <div className="flex space-x-2">
            <Button onClick={toggleCalculator} className="btn btn-primary flex items-center justify-center">
              <CalculatorIcon className="w-5 h-5 mr-2" />
              <span>Calculator</span>
            </Button>
            <Button onClick={toggleSpreadsheet} className="btn btn-primary flex items-center justify-center">
              <Table className="w-5 h-5 mr-2" />
              <span>Sheet</span>
            </Button>
            <Button onClick={toggleFeedback} className="btn btn-primary flex items-center justify-center">
              <HelpCircle className="w-5 h-5 mr-2" />
              <span>Feedback</span>
            </Button>
            <Button onClick={triggerEndTestlet} className="btn btn-orange hover:bg-orange-600">
              End Testlet
            </Button>
          </div>
        </div>

        {/* Mobile tools dropdown */}
        {showMobileTools && (
          <div className="sm:hidden flex flex-col space-y-2 mb-4">
            <Button onClick={toggleCalculator} className="btn btn-primary flex items-center justify-center">
              <CalculatorIcon className="w-5 h-5 mr-2" />
              <span>Calculator</span>
            </Button>
            <Button onClick={toggleSpreadsheet} className="btn btn-primary flex items-center justify-center">
              <Table className="w-5 h-5 mr-2" />
              <span>Sheet</span>
            </Button>
            <Button onClick={toggleFeedback} className="btn btn-primary flex items-center justify-center">
              <HelpCircle className="w-5 h-5 mr-2" />
              <span>Feedback</span>
            </Button>
          </div>
        )}

        <div className="quiz-content mb-4 text-gray-900 dark:text-dark-text" dangerouslySetInnerHTML={renderHTML(questionText)} />
        
        {isTableQuestion ? (
          <>
            <div 
              className="quiz-content text-gray-900 dark:text-dark-text" 
              dangerouslySetInnerHTML={renderHTML(tableHTML)} 
              onClick={handleTableClick}
            />
          </>
        ) : (
          <div className="space-y-2">
          {options.map((option, index) => {
            const optionLetter = String.fromCharCode(65 + index);
            const isSelected = selectedAnswer === optionLetter;
            const hasConfidence = !!confidenceLevel;
      
            let buttonClasses = [
              "answer-option",
              "w-full",
              "text-left",
              "px-4",
              "py-2",
              "rounded",
              "transition-colors",
              "duration-300",
              "text-gray-900",
              "dark:text-white",
              "bg-gray-50",
              "hover:bg-gray-100",
              "dark:bg-gray-700",
              "dark:hover:bg-gray-600",
            ];
      
            if (isSelected && !showExplanation) {
              buttonClasses.push("selected", "bg-blue-400", "hover:bg-blue-300", "text-white", "dark:bg-orange-600", "dark:hover:bg-orange-700", "border-transparent");
              if (hasConfidence) {
                buttonClasses.push("confidence-selected");
              } else {
                buttonClasses.push("no-confidence-selected");
              }
            }
      
            if (showExplanation) {
              if (optionLetter === question.correct_answer) {
                buttonClasses.push("correct", "bg-green-500", "hover:bg-green-600", "dark:bg-green-700", "dark:hover:bg-green-800", "text-white", "border-transparent");
              } else if (isSelected) {
                buttonClasses.push("incorrect", "bg-red-500", "hover:bg-red-600", "dark:bg-red-700", "dark:hover:bg-red-800", "text-white", "border-transparent");
              }
            }
      
            return (
              <button
                key={index}
                onClick={() => handleAnswer(optionLetter)}
                className={buttonClasses.join(" ")}
              >
                {option}
              </button>
            );
          })}
        </div>
      )}
        {!showExplanation && (
          <div className="mt-4 sm:mt-8 bg-gray-50 dark:bg-gray-800 rounded-lg shadow-md p-4 sm:p-6">
            <div className="space-y-4 sm:space-y-6">
              <p className="text-base sm:text-lg font-semibold text-gray-900 dark:text-gray-200">
                {selectedAnswer 
                  ? "How confident are you in your answer?" 
                  : "Select an answer, then rate your confidence"}
              </p>
              <div className="flex flex-col sm:grid sm:grid-cols-3 gap-2 sm:gap-4">
                {confidenceLevels.map((level) => {
                  const isLastConfidenceLevel = confidenceHistory[question.id] === level;
                  const isSelected = confidenceLevel === level;
                  const getColorClass = (level: string) => {
                    switch(level) {
                      case 'guessing': return 'bg-red-500 hover:bg-red-600 dark:bg-red-700 dark:hover:bg-red-600';
                      case 'maybe': return 'bg-yellow-500 hover:bg-yellow-600 dark:bg-yellow-700 dark:hover:bg-yellow-600';
                      case 'confident': return 'bg-green-500 hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-600';
                      default: return 'bg-gray-400 hover:bg-gray-500 dark:bg-gray-600 dark:hover:bg-gray-500';
                    }
                  };
                  return (
                    <button
                      key={level}
                      onClick={() => handleConfidenceSelection(level)}
                      disabled={!selectedAnswer}
                      className={`
                        py-3 px-4 rounded-lg font-medium transition-all duration-200 ease-in-out
                        ${isSelected 
                          ? `${getColorClass(level)} text-white border border-transparent` // Added border-transparent for consistency
                          : 'bg-white text-gray-800 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600 border border-gray-300 dark:border-gray-600'} // Added border classes
                        ${!selectedAnswer && 'opacity-50 cursor-not-allowed'}
                        ${isSelected && 'ring-2 ring-offset-2 ring-offset-gray-100 dark:ring-offset-gray-800 ring-blue-500'}
                      `}
                    >
                      {level.charAt(0).toUpperCase() + level.slice(1)}
                      {isLastConfidenceLevel && !isSelected && (
                        <span className="ml-1 text-xs">(Previous)</span>
                      )}
                    </button>
                  );
                })}
              </div>
              <button
                onClick={handleSubmitAnswer}
                disabled={!selectedAnswer || !confidenceLevel || isSubmitting}
                className={`
                  w-full py-3 px-6 rounded-lg text-white font-semibold transition-all duration-200 ease-in-out relative
                  ${selectedAnswer && confidenceLevel && !isSubmitting
                    ? 'bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700'
                    : 'bg-gray-300 dark:bg-gray-600 text-gray-500 dark:text-gray-400 cursor-not-allowed'}
                `}
              >
                {isSubmitting && (
                  <span className="absolute inset-0 flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                )}
                <span className={isSubmitting ? 'invisible' : ''}>Submit Answer</span>
              </button>
            </div>
          </div>
        )}
        {!isTestMode && showExplanation && (
          <div className="explanation mt-4 sm:mt-8">
            <p className="font-bold mb-2 sm:mb-4 text-lg sm:text-xl text-gray-900 dark:text-dark-text">Explanation:</p>
            <div className="quiz-content text-gray-900 dark:text-dark-text mb-4 sm:mb-6" dangerouslySetInnerHTML={renderHTML(question.answer_content)} />
            <button
              onClick={handleNextOrFinish}
              className={`
                w-full py-3 px-6 rounded-lg text-white font-semibold text-base sm:text-lg
                transition-all duration-200 ease-in-out relative
                flex items-center justify-center
                ${isFinishing || isLoadingNextQuestion
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700'}
              `}
              disabled={isFinishing || isLoadingNextQuestion}
            >
              {(isFinishing || isLoadingNextQuestion) ? (
                <span className="absolute inset-0 flex items-center justify-center">
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </span>
              ) : (
                <>
                  <span>{isLastQuestion ? 'Finish Quiz' : 'Next Question'}</span>
                  {isLastQuestion ? (
                    <CheckCircle className="ml-2 h-5 w-5" />
                  ) : (
                    <ChevronRight className="ml-2 h-5 w-5" />
                  )}
                </>
              )}
            </button>
          </div>
        )}
      </div>
      <div className="mt-4">
        {renderNoteSection()}
      </div>
      
      {/* New components */}
      <EndTestlet
        isOpen={showEndTestletModal}
        onClose={() => setShowEndTestletModal(false)}
        onEndTestlet={handleEndTestlet}
        isLastQuestion={isLastQuestion}
        questionsAnswered={currentQuestion + 1}
        totalQuestions={questions.length}
        isViewingExplanation={showExplanation}
      />
      <Calculator 
        isOpen={showCalculator} 
        onClose={() => setShowCalculator(false)}
      />
      <Spreadsheet 
        isOpen={showSpreadsheet} 
        onClose={() => setShowSpreadsheet(false)}
        onFocusChange={setIsSpreadsheetFocused}
      />
      <FeedbackPopup 
        isOpen={showFeedback} 
        onClose={toggleFeedback}
        questionId={question.id}
      />
    </>
  );
};

  const handleTableClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const cell = target.closest('td');
    if (cell && cell.querySelector('.multi-choice')) {
      const multiChoice = cell.querySelector('.multi-choice');
      if (multiChoice) {
        const choice = multiChoice.getAttribute('data-choice');
        if (choice) {
          handleAnswer(choice);
          // Remove 'selected' class from all cells
          cell.closest('table')?.querySelectorAll('td').forEach(td => td.classList.remove('selected'));
          // Add 'selected' class to the clicked cell
          cell.classList.add('selected');
        }
      }
    }
  };

  const handleResetCategories = () => {
    if (selectedCategories.length > 0 && !isResettingCategories) {
      setShowResetPopup(true);
    }
  };

  const handleConfirmReset = async () => {
    setIsResettingCategories(true);
    try {
      await resetCategories.mutateAsync({ categoryIds: selectedCategories });
      
      // Clear local quiz state
      setQuestions([]);
      setCurrentQuestion(0);
      setUserAnswers({});
      setQuizCompleted(false);
      setQuizSummary([]);
      setSelectedCategories([]);
    } catch (err) {
      console.error('Failed to reset categories:', err);
      setError(`Failed to reset categories. Please try again. Error: ${err instanceof Error ? err.message : JSON.stringify(err)}`);
    } finally {
      setIsResettingCategories(false);
      setShowResetPopup(false);
    }
  };

const handleNavigateToAnalytics = () => {
  navigate(`/course/${courseId}/analytics`);
};

const handleCloseSummary = useCallback(() => {
  // Reset all necessary states
  setCurrentView('categories');
  setQuizStarted(false);
  setQuizCompleted(false);
  setViewingCompletedQuiz(false);
  setShowSummary(false);
  setSelectedCategories([]);
  setQuestions([]);
  setCurrentQuestion(0);
  setUserAnswers({});
  setQuizSummary([]);
  
  // Reset states related to the current question
  setSelectedAnswer(null);
  setShowExplanation(false);
  setConfidenceLevel(null);
  setQuestionStartTime(null);
  setElapsedTime(0);

  // Reset timer-related states and refs
  setQuizStartTime(null);
  quizStartTimeRef.current = null;
  setPausedTime(0);
  pausedTimeRef.current = 0;
  setIsPaused(false);
  if (timerRef.current) {
    clearInterval(timerRef.current);
    timerRef.current = null;
  }
  
  // Reset note-related states
  setNote('');
  setCurrentNote(null);
  setShowNoteEditor(false);
  setNoteContent('');
  setIsEditingNote(false);
  
  // Clear the active quiz reference
  activeQuizRef.current = null;
  
  // Ensure the popup is not shown
  setShowPopup(false);
  
  // Reset any other state variables that might affect the quiz state
  setIsSubmitting(false);
  setIsFinishing(false);
  setIsLoadingNextQuestion(false);
  
  // Force a re-render by updating the URL
  navigate(`/course/${courseId}/quiz`, { replace: true });
  
  // Optionally, you can add a small delay before navigating to ensure all state updates have been processed
  setTimeout(() => {
    navigate(`/course/${courseId}/quiz`, { replace: true });
  }, 0);
}, [courseId, navigate]);

const capitalizeFirstLetter = (string: string): string => {
return string.charAt(0).toUpperCase() + string.slice(1);
};



const renderNoteSection = () => {
  if (!questions.length || currentQuestion >= questions.length) {
    return null;
  }

  const currentQuestionData = questions[currentQuestion];
  const currentNote = currentQuestionData.note;

  return (
    <div className="space-y-6 mt-4">
      <div className="bg-light-background dark:bg-dark-secondaryLight p-4 rounded shadow">
        <h3 className="text-xl font-bold mb-4 text-light-text dark:text-dark-text">
          {currentNote ? (isEditingNote ? 'Edit Note' : 'Your Note') : 'Create New Note'}
        </h3>
        {(!currentNote || isEditingNote) ? (
          <>
            <textarea
              placeholder="Note Content"
              value={noteContent}
              onChange={(e) => {
                const sanitizedContent = sanitizeInput(e.target.value);
                setNoteContent(sanitizedContent.slice(0, MAX_NOTE_LENGTH));
              }}
              onFocus={() => setIsNoteTextareaFocused(true)}
              onBlur={() => setIsNoteTextareaFocused(false)}
              className="w-full p-2 mb-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
              rows={3}
              maxLength={MAX_NOTE_LENGTH}
            ></textarea>
            <p className="text-sm text-gray-500 mb-2">
                {noteContent.length}/{MAX_NOTE_LENGTH} characters
              </p>
            <div className="space-x-2">
              <button
                onClick={handleUpdateNote}
                className="bg-light-primary dark:bg-dark-primary text-white px-4 py-2 rounded hover:bg-light-accent dark:hover:bg-dark-accent"
              >
                {currentNote ? 'Save Edit' : 'Create Note'}
              </button>
              {isEditingNote && currentNote && (
                <button
                  onClick={() => {
                    setIsEditingNote(false);
                    setNoteContent(sanitizeInput(currentNote.content));
                  }}
                  className="bg-gray-500 dark:bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-600 dark:hover:bg-gray-700"
                >
                  Cancel
                </button>
              )}
            </div>
          </>
          ) : currentNote ? (
            <>
              <p className="mt-2 text-light-text dark:text-dark-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentNote.content) }}></p>
              <div className="mt-4 space-x-2">
                <button
                  onClick={() => {
                    setIsEditingNote(true);
                    setNoteContent(currentNote.content);
                  }}
                  className="bg-yellow-500 dark:bg-yellow-600 text-white px-4 py-2 rounded hover:bg-yellow-600 dark:hover:bg-yellow-700"
                >
                  Edit
                </button>
                <button
                  onClick={handleClearNote}
                  className="bg-red-500 dark:bg-red-600 text-white px-4 py-2 rounded hover:bg-red-600 dark:hover:bg-red-700"
                >
                  Clear
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  };


const renderQuizSummary = ({ onClose }: { onClose: () => void }) => {
  const renderHTML = (html: string) => ({ __html: DOMPurify.sanitize(html) });
    
  // Remove this block and replace with a comment:
  // Loading screen is now handled in the main render method
  
  if (error) return <div className="text-red-500 dark:text-red-400">{error}</div>;

  if (isFinishing) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center">
          <div className="spinner border-light-primary dark:border-dark-primary"></div>
          <p className="mt-4 text-light-text dark:text-dark-text">Finishing quiz...</p>
        </div>
      </div>
    );
  }

  const answeredQuestions = quizSummary.filter(item => item.userAnswer !== null);
  const totalTime = answeredQuestions.reduce((sum, item) => sum + (item.timeSpent || 0), 0);
  const averageTime = answeredQuestions.length > 0 ? totalTime / answeredQuestions.length : 0;

  const filteredItems = answeredQuestions.filter(item => {
    if (summaryFilter === 'all') return true;
    if (summaryFilter === 'correct') return item.isCorrect;
    if (summaryFilter === 'incorrect') return !item.isCorrect;
    return item.confidenceLevel.toLowerCase() === summaryFilter;
  });

  // Function to toggle note editing state
  const toggleNoteEditing = (index: number) => {
    setEditingNotes(prev => ({ ...prev, [index]: !prev[index] }));
    if (!editingNotes[index]) {
      // If we're starting to edit, initialize the note content
      setNoteContents(prev => ({ ...prev, [index]: filteredItems[index].note?.content || '' }));
    }
  };

  // Updated function to handle saving notes
  const handleSaveSummaryNote = async (index: number) => {
    if (!currentUser) return;
    const item = filteredItems[index];
    try {
      const updatedNote = await api.updateNote(
        item.note?.id || null,
        { content: noteContents[index] || '' },
        currentUser.id,
        courseId,
        item.questionId,
        null,
        null
      );
      
      // Update the quizSummary state with the new note
      setQuizSummary(prevSummary => 
        prevSummary.map(summaryItem => 
          summaryItem.questionId === item.questionId 
            ? { ...summaryItem, note: updatedNote } 
            : summaryItem
        )
      );

      // Reset the editing state for this note
      toggleNoteEditing(index);

      await api.logUserActivity({
        user_id: currentUser.id,
        course_id: courseId,
        activity_type: item.note ? 'update_note' : 'create_note',
        activity_id: updatedNote.id,
        result: 'success'
      });
    } catch (err) {
      console.error('Failed to save note:', err);
      setError('Failed to save note. Please try again.');
    }
  };

  // Updated function to handle clearing notes
  const handleClearSummaryNote = async (index: number) => {
    const item = filteredItems[index];
    if (item.note) {
      try {
        await api.deleteNote(item.note.id);
        
        // Update the quizSummary state to remove the note
        setQuizSummary(prevSummary => 
          prevSummary.map(summaryItem => 
            summaryItem.questionId === item.questionId 
              ? { ...summaryItem, note: null } 
              : summaryItem
          )
        );

        // Reset the note content for this index
        setNoteContents(prev => {
          const newContents = { ...prev };
          delete newContents[index];
          return newContents;
        });

      } catch (err) {
        console.error('Failed to clear note:', err);
        setError('Failed to clear note. Please try again.');
      }
    }
  };

  return (
    <div className="space-y-4 md:space-y-8">
      <h2 className="text-xl md:text-2xl font-bold text-light-text dark:text-dark-text">Quiz Summary</h2>
      
      {/* Quiz statistics and action buttons */}
      <div className="bg-light-background dark:bg-dark-secondaryLight p-4 rounded-lg shadow">
        <div className="flex flex-col space-y-4 md:flex-row md:justify-between md:items-center">
          <div className="space-y-2 md:space-y-1">
            <p className="text-sm md:text-base text-light-text dark:text-dark-text">
              <span className="font-semibold">Total Time:</span> {formatTime(totalTime)}
            </p>
            <p className="text-sm md:text-base text-light-text dark:text-dark-text">
              <span className="font-semibold">Avg Time/Question:</span> {formatTime(averageTime)}
            </p>
          </div>
          <div className="grid grid-cols-1 gap-2 md:flex md:flex-row md:space-x-4">
            <button 
              onClick={handleNavigateToAnalytics} 
              className="btn btn-secondary w-full md:w-auto"
            >
              Analytics
            </button>
            <button 
              onClick={handleCloseSummary}
              className="btn btn-primary w-full md:w-auto"
            >
              Close Summary
            </button>
          </div>
        </div>
      </div>
  
      {/* Filter results */}
      <div className="bg-light-background dark:bg-dark-secondaryLight pt-5 pb-1 px-4 rounded-lg shadow">
        <QuizSummaryFilter 
          filter={summaryFilter} 
          setFilter={handleSummaryFilterChange} 
          counts={filterCounts}
          currentSummary={filteredQuizHistory}
        />
      </div>

        {filteredItems.slice(indexOfFirstItem, indexOfLastItem).map((item: QuizSummary, index: number) => (
        <div key={index} className="bg-light-background dark:bg-dark-secondaryLight p-4 md:p-6 rounded-lg shadow space-y-4">
          <div>
            <p className="text-lg font-bold text-light-text dark:text-dark-text">{item.parentCategory}</p>
            {item.subCategory && (
              <p className="text-sm text-gray-500 dark:text-gray-400">{item.subCategory}</p>
            )}
          </div>
          <div className="flex flex-col space-y-2 md:flex-row md:justify-between md:items-center text-sm text-gray-500 dark:text-gray-400">
            <p>Question ID: {item.questionId}</p>
            <p><strong>Confidence:</strong> {capitalizeFirstLetter(item.confidenceLevel)}</p>
            <p><strong>Time:</strong> {formatTime(item.timeSpent || 0)}</p>
          </div>
          <hr className="border-t border-light-text dark:border-dark-text" />
          <div className="space-y-2">
            <div className="quiz-content text-light-text dark:text-dark-text" dangerouslySetInnerHTML={renderHTML(item.question)} />
            {item.romanList && <div className="quiz-content text-light-text dark:text-dark-text" dangerouslySetInnerHTML={renderHTML(item.romanList)} />}
            {item.isTableQuestion ? (
              <div className="quiz-content text-light-text dark:text-dark-text" dangerouslySetInnerHTML={renderHTML(item.tableHTML)} />
            ) : (
              item.options.length > 0 && (
                <div className="space-y-2">
                  {item.options.map((option, optionIndex) => {
                    const optionLetter = String.fromCharCode(65 + optionIndex);
                    let optionClasses = [
                      "answer-option",
                      "w-full",
                      "text-left",
                      "px-4",
                      "py-2",
                      "rounded",
                      "transition-colors",
                      "duration-300",
                      "text-light-text",
                      "dark:text-dark-text",
                      "border",
                      "border-gray-400",
                      "dark:border-gray-500",
                    ];

                    if (optionLetter === item.userAnswer) {
                      optionClasses.push("selected");
                      if (optionLetter === item.correctAnswer) {
                        optionClasses.push("correct", "bg-green-500", "hover:bg-green-600", "dark:bg-green-700", "dark:hover:bg-green-800", "text-white");
                      } else {
                        optionClasses.push("incorrect", "bg-red-500", "hover:bg-red-600", "dark:bg-red-700", "dark:hover:bg-red-800", "text-white");
                      }
                    } else if (optionLetter === item.correctAnswer) {
                      optionClasses.push("correct", "bg-green-500", "hover:bg-green-600", "dark:bg-green-700", "dark:hover:bg-green-800", "text-white");
                    }

                    return (
                      <div
                        key={optionIndex}
                        className={optionClasses.join(" ")}
                      >
                        {option}
                      </div>
                    );
                  })}
                </div>
              )
            )}
          </div>
          <hr className="border-t border-light-text dark:border-dark-text" />
          <div className="mt-4 space-y-2">
            <p className="font-bold text-light-text dark:text-dark-text">Explanation:</p>
            <div className="quiz-content text-light-text dark:text-dark-text" dangerouslySetInnerHTML={renderHTML(item.answerContent)} />
          </div>
          <hr className="border-t border-light-text dark:border-dark-text" />
          <div className="mt-4 pb-4">
            <h4 className="font-bold mb-2 text-light-text dark:text-dark-text">Your Note:</h4>
            {editingNotes[index] ? (
              <div className="bg-light-background dark:bg-dark-background p-4 rounded shadow">
                <textarea
                  placeholder="Note Content"
                  value={noteContents[index] || ''}
                  onChange={(e) => handleNoteChange(index, e.target.value)}
                  className="w-full p-2 mb-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
                  rows={3}
                  maxLength={MAX_NOTE_LENGTH}
                ></textarea>
                <p className="text-sm text-gray-500 mb-2">
                    {(noteContents[index] || '').length}/{MAX_NOTE_LENGTH} characters
                  </p>
                <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2">
                  <button
                    onClick={() => handleSaveSummaryNote(index)}
                    className="bg-light-primary dark:bg-dark-primary text-white px-4 py-2 rounded hover:bg-light-accent dark:hover:bg-dark-accent w-full md:w-auto"
                  >
                    {item.note ? 'Update Note' : 'Create Note'}
                  </button>
                  <button
                    onClick={() => toggleNoteEditing(index)}
                    className="bg-gray-500 dark:bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-600 dark:hover:bg-gray-700 w-full md:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : item.note ? (
              <>
                <p className="text-light-text dark:text-dark-text" dangerouslySetInnerHTML={{ __html: sanitizeInput(item.note.content) }}></p>
                <div className="mt-2 flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2">
                  <button
                    onClick={() => toggleNoteEditing(index)}
                    className="bg-yellow-500 dark:bg-yellow-600 text-white px-4 py-2 rounded hover:bg-yellow-600 dark:hover:bg-yellow-700 w-full md:w-auto"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleClearSummaryNote(index)}
                    className="bg-red-500 dark:bg-red-600 text-white px-4 py-2 rounded hover:bg-red-600 dark:hover:bg-red-700 w-full md:w-auto"
                  >
                    Clear
                  </button>
                </div>
              </>
            ) : (
              <button
                onClick={() => toggleNoteEditing(index)}
                className="bg-light-primary dark:bg-dark-primary text-white px-4 py-2 rounded hover:bg-light-accent dark:hover:bg-dark-accent w-full md:w-auto"
              >
                Add Note
              </button>
            )}
          </div>
        </div>
      ))}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
      />
    </div>
  );
};
 
  // If we're viewing a completed quiz from analytics or if viewingCompletedQuiz is true, render the quiz summary
  // Main render logic
  // First, check if we should show the quiz summary
  return (
    <>
      {/* Loading screens and notifications */}
      {isResumingQuiz && (
        <LoadingScreen 
          message="Resuming Quiz..." 
          subMessage="Please wait while we load your quiz."
        />
      )}
      {isEndingQuiz && (
        <LoadingScreen 
          message="Ending Quiz..." 
          subMessage="Please wait while we process your results."
        />
      )}
      {isLoadingQuizSummary && (
        <LoadingScreen 
          message="Loading Quiz Summary..." 
          subMessage="Please wait while we process your results."
        />
      )}
      
      {isResettingCategories && (
      <LoadingScreen 
        message="Resetting Categories..." 
        subMessage="Please wait while we reset your selected categories. This may take up to 2-5 minutes, do not leave the"
      />
    )}
    
    {quizCompleted || showSummary ? (
      renderQuizSummary({ onClose: handleCloseSummary })
    ) : currentView === 'categories' && !quizStarted ? (
      <div className="space-y-4 relative pb-20">
        <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
          <h2 className="text-2xl font-bold mb-4 sm:mb-0">Custom Quiz</h2>
          <button
            onClick={handleResetCategories}
            disabled={selectedCategories.length === 0 || isResettingCategories}
            className={`w-full sm:w-auto text-red-500 dark:text-red-400 dark:hover:text-red-400 disabled:text-gray-300 dark:disabled:text-gray-600 ${
              selectedCategories.length === 0 || isResettingCategories
                ? 'cursor-not-allowed'
                : 'hover:text-red-600 dark:hover:bg-gray-800'
            } p-2 rounded`}
          >
            Reset Categories
          </button>
        </div>

          <div className="bg-white p-4 rounded shadow dark:bg-dark-background">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
              {/* Other possible buttons or content */}
            </div>

            {/* Updated table structure to match StudyPlan */}
            <div className="overflow-x-auto shadow rounded-lg relative">
              <div
                ref={tableRef}
                className="overflow-x-scroll max-w-full scrollbar-hide"
                onScroll={handleScroll}
              >
                {renderCategories()}
              </div>
              {showScrollIndicator && (
                <>
                  <div className="absolute -right-[3px] top-0 bottom-0 w-[10%] bg-gradient-to-l from-white dark:from-gray-800 to-transparent pointer-events-none" />
                  <div className="fixed right-0 top-1/2 -translate-y-1/2 w-[10%] h-full pointer-events-none">
                    {/* You can add a chevron icon here if needed */}
                  </div>
                </>
              )}
            </div>
          </div>

          <QuizCustomizer
            courseId={courseId}
            userId={user?.id || ''}
            show={showCustomizer}
            availableQuestionCounts={availableQuestionCounts || {}}
            customQuestionCount={customQuestionCount}
            setCustomQuestionCount={setCustomQuestionCount}
            keslerQFilter={keslerQFilter}
            setKeslerQFilter={setKeslerQFilter}
            onStartQuiz={handleStartQuiz}
            onResumeQuiz={handleResumeQuiz}
            selectedCategories={selectedCategories}
          />
        
          {/* Only show PopupModal if there's an active quiz and showPopup is true */}
          {showPopup && activeQuizRef.current && (
            <PopupModal
              isOpen={showPopup}
              onClose={() => {
                setShowPopup(false);
                setIsModalOpen(false); // Update the global modal state
              }}
              onResume={() => {
                setCurrentView('quiz');
                setQuizStarted(true);
                setShowPopup(false);
                setIsModalOpen(false); // Update the global modal state
              }}
              onEnd={() => {
                if (activeQuizRef.current) {
                  handleEndQuizSession(activeQuizRef.current.id);
                  activeQuizRef.current = null;
                }
                setIsModalOpen(false); // Update the global modal state
              }}
            />
          )}

          {/* Add QuizResetCategoryPopup here */}
          <QuizResetCategoryPopup 
            isOpen={showResetPopup}
            onClose={() => setShowResetPopup(false)}
            onConfirm={handleConfirmReset}
            categoryCount={selectedCategories.length}
          />
        </div>
      ) : currentView === 'quiz' && quizStarted ? (
        <div className="flex flex-col md:flex-row">
          <div className="flex-grow px-2 sm:px-4">
            <div className="space-y-4 text-light-text dark:text-dark-text">
              <div className="flex justify-between items-center">
                <h2 className="text-xl sm:text-2xl font-bold">Question {currentQuestion + 1} of {questions.length}</h2>
                <div className="text-light-text dark:text-dark-text"><strong>Time: {formatTime(elapsedTime)}</strong></div>
              </div>
              {renderQuestion()}
            
              {showNoteEditor && (
                <div className="mt-4 bg-white p-4 rounded shadow">
                  <h3 className="text-xl font-bold mb-2">Edit Note</h3>
                  <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    className="w-full h-32 p-2 border rounded"
                    placeholder="Enter your note here..."
                  />
                  <div className="mt-2 space-x-2">
                    <button
                      onClick={handleSaveNote}
                      className="btn btn-primary"
                    >
                      Save Changes
                    </button>
                    <button
                      onClick={() => setShowNoteEditor(false)}
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {currentNote && !showNoteEditor && (
                <div className="mt-4 bg-white p-4 rounded shadow">
                  <h3 className="text-xl font-bold mb-2">Your Note</h3>
                  <p>{currentNote.content}</p>
                  <div className="mt-2 space-x-2">
                    <button onClick={handleNoteTaking} className="btn btn-secondary">
                      Edit Note
                    </button>
                    <button onClick={handleClearNote} className="btn btn-secondary">
                      Clear Note
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default React.memo(Quiz);