import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ProfileDropdown from './ProfileDropdown';
import BottomNavigation from './mobile/BottomNavigation';
import CourseDropdown from './CourseDropdown';
import { useAuth } from '../AuthProvider';
import { useTheme } from '../../contexts/ThemeContext';
import TextSelectionPopup from '../course/PiracyProtection/TextSelectionPopup';
import Sidebar from '../../pages/Sidebar';
import { Menu } from 'react-feather';
import Footer from './Footer'; // Import the new Footer component

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  const location = useLocation();
  const { courseId } = useParams<{ courseId?: string }>();
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCoursePage, setIsCoursePage] = useState(false);
  const [currentCourseId, setCurrentCourseId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const isCourse = pathParts[1] === 'course' && pathParts[2];
    setIsCoursePage(!!isCourse);
    setCurrentCourseId(isCourse ? pathParts[2] : undefined);
  }, [location]);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark bg-gray-900' : 'bg-gray-100'}`}>
      <div className="flex flex-col min-h-screen">
        <header className="bg-white shadow-sm dark:bg-gray-800 sticky top-0 z-10">
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6">
            <div className="flex justify-between items-center h-16">
              <div className="flex items-center">
                {/* Hamburger button for mobile and tablet (hidden at 1350px+) */}
                <button
                  onClick={() => setIsMenuOpen(true)}
                  className="hamburger-btn mr-2 custom-lg:hidden text-gray-600 hover:text-gray-800 focus:outline-none dark:text-gray-300 dark:hover:text-gray-100 w-10 h-10 flex items-center justify-center"
                >
                  <Menu size={24} />
                </button>
                {/* Course Dropdown for tablet and desktop */}
                {isCoursePage && (
                  <div className="hidden md:flex items-center cursor-pointer">
                    <CourseDropdown currentCourseId={currentCourseId} />
                  </div>
                )}
              </div>
              {/* Centered logo */}
              <Link to="/products" className="pt-1.5 flex-shrink-0 absolute left-1/2 transform -translate-x-1/2">
                <img
                  src={`${process.env.PUBLIC_URL}/logo/kesler-course-logo-blue-orange.png`}
                  alt="Kesler CPA Review"
                  className="h-8 w-auto dark:hidden"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/logo/kesler-course-logo-white.png`}
                  alt="Kesler CPA Review"
                  className="h-8 w-auto hidden dark:block"
                />
              </Link>
              {/* Profile Dropdown */}
              <div className="flex items-center">
                <ProfileDropdown />
              </div>
              </div>
          </div>
        </header>
        <div className="flex-grow flex">
          {/* Sidebar for desktop */}
          <div className="hidden custom-lg:block">
            <Sidebar
              courseId={currentCourseId}
              user={user}
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
            />
          </div>
          {/* Main content */}
          <main className={`flex-grow transition-all duration-300 w-full ${isCoursePage ? 'custom-lg:ml-[0px]' : ''}`}>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 py-4 sm:py-6">
              {children}
            </div>
          </main>
        </div>
        <Footer /> {/* Add the Footer component here */}

      </div>
      {/* Mobile and Tablet Sidebar */}
      {isMenuOpen && (
        <div className="custom-lg:hidden">
          <Sidebar
            courseId={currentCourseId}
            user={user}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        </div>
      )}
      <BottomNavigation currentPath={location.pathname} isInsideCourse={isCoursePage} />
      {user && <TextSelectionPopup courseId={currentCourseId || ''} />}
    </div>
  );
}

export default Layout;