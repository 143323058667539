import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { api } from '../services/api';
import { useAuth } from './AuthProvider';

interface UserCourseAccess {
  user_id: string;
  course_id: string;
  // Add any other properties that are in the UserCourseAccess interface
}

interface UserCourseAccessContextType {
  userCourseAccess: UserCourseAccess[];
  loading: boolean;
  error: Error | null;
  getUserCourseAccess: () => Promise<UserCourseAccess[]>;
}

const UserCourseAccessContext = createContext<UserCourseAccessContextType | undefined>(undefined);

export function UserCourseAccessProvider({ children }: { children: React.ReactNode }) {
  const [userCourseAccess, setUserCourseAccess] = useState<UserCourseAccess[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { user } = useAuth();

  const getUserCourseAccess = useCallback(async () => {
    if (!user) {
      setUserCourseAccess([]);
      return [];
    }

    try {
      setLoading(true);
      const data = await api.getUserCourseAccess(user.id);
      setUserCourseAccess(data);
      setLoading(false);
      return data;
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      return [];
    }
  }, [user]);

  useEffect(() => {
    getUserCourseAccess();
  }, [getUserCourseAccess]);

  const value = {
    userCourseAccess,
    loading,
    error,
    getUserCourseAccess,
  };

  return <UserCourseAccessContext.Provider value={value}>{children}</UserCourseAccessContext.Provider>;
}

export function useUserCourseAccess() {
  const context = useContext(UserCourseAccessContext);
  if (context === undefined) {
    throw new Error('useUserCourseAccess must be used within a UserCourseAccessProvider');
  }
  return context;
}