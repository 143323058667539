import React, { createContext, useContext, useCallback, useRef } from 'react';
import { useQuery, UseQueryResult, QueryClient, QueryClientProvider } from 'react-query';
import { api } from '../services/api';

interface Product {
  id: string;
  title: string;
  description: string;
  // Add other product properties as needed
}

interface ProductContextType {
  products: { [id: string]: Product };
  loading: boolean;
  error: unknown;
  refreshProducts: () => void;
  getProduct: (id: string) => Promise<Product | null>;
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000, // 1 hour
      cacheTime: 24 * 60 * 60 * 1000, // 24 hours
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

export function ProductProvider({ children }: { children: React.ReactNode }) {
  const courseCache = useRef<{ [id: string]: Product }>({});

  const fetchProducts = useCallback(async () => {
    const products = await api.getProducts();
    products.forEach(product => {
      courseCache.current[product.id] = product;
    });
    return products.reduce((acc, product) => {
      acc[product.id] = product;
      return acc;
    }, {} as { [id: string]: Product });
  }, []);

  const {
    data: products = {},
    isLoading,
    error,
    refetch,
  }: UseQueryResult<{ [id: string]: Product }, unknown> = useQuery('products', fetchProducts, {
    initialData: () => {
      const cachedProducts = localStorage.getItem('cachedProducts');
      return cachedProducts ? JSON.parse(cachedProducts) : {};
    },
  });

  const getProduct = useCallback(async (id: string): Promise<Product | null> => {
    if (courseCache.current[id]) {
      return courseCache.current[id];
    }

    try {
      const product = await queryClient.fetchQuery(['course', id], () => api.getCourse(id), {
        staleTime: 60 * 60 * 1000, // 1 hour
      });
      courseCache.current[id] = product;
      queryClient.setQueryData('products', (oldData: { [id: string]: Product } | undefined) => ({
        ...oldData,
        [product.id]: product,
      }));
      return product;
    } catch (err) {
      console.error('Failed to fetch the product:', err);
      return null;
    }
  }, []);

  const value: ProductContextType = {
    products,
    loading: isLoading,
    error,
    refreshProducts: refetch,
    getProduct,
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
    </QueryClientProvider>
  );
}

export function useProducts(): ProductContextType {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('useProducts must be used within a ProductProvider');
  }
  return context;
}