import React, { useState } from 'react';
import { Modal } from '../../../components/ui/Modal';
import { Button } from '../../../components/ui/Button';
import { Textarea } from '../../../components/ui/Textarea';
import DOMPurify from 'dompurify';

interface FeedbackPopupProps {
  isOpen: boolean;
  onClose: () => void;
  questionId: string;
}

const MAX_FEEDBACK_LENGTH = 4000;

const FeedbackPopup: React.FC<FeedbackPopupProps> = ({ isOpen, onClose, questionId }) => {
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log(`Feedback for question ${questionId}:`, feedback);
    // await api.sendFeedback(questionId, feedback);
    onClose();
  };

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const sanitizedContent = DOMPurify.sanitize(e.target.value);
    setFeedback(sanitizedContent.slice(0, MAX_FEEDBACK_LENGTH));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-4 sm:p-6 bg-white dark:bg-gray-800 max-w-[90vw] sm:max-w-lg w-full rounded-lg shadow-lg">
        <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-gray-800 dark:text-white">
          Provide Feedback
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <Textarea
            value={feedback}
            onChange={handleFeedbackChange}
            placeholder="Enter your feedback here..."
            className="w-full bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
            rows={4}
            aria-label="Feedback input"
            maxLength={MAX_FEEDBACK_LENGTH}
          />
          <p className="text-sm text-gray-500">
            {feedback.length}/{MAX_FEEDBACK_LENGTH} characters
          </p>
          <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
            <Button 
              type="button" 
              onClick={onClose} 
              variant="outline"
              className="w-full sm:w-auto bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 hover:text-gray-900 dark:hover:text-white"
            >
              Cancel
            </Button>
            <Button 
              type="submit"
              className="w-full sm:w-auto bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white"
            >
              Submit Feedback
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FeedbackPopup;