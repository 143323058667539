import React, { useState, useEffect } from 'react';
import ProductList from '../components/products/ProductList';
import { useAuth } from '../components/AuthProvider';
import { useProducts } from '../components/ProductProvider';
import { useUserCourseAccess } from '../components/UserCourseAccessProvider';

interface Product {
  id: string;
  title: string;
  description: string;
}

function Products() {
  const [products, setProducts] = useState<Product[]>([]);
  const { user } = useAuth();
  const { getProduct, loading: productsLoading, error: productsError } = useProducts();
  const { userCourseAccess, loading: accessLoading, error: accessError } = useUserCourseAccess();

  useEffect(() => {
    async function fetchProducts() {
      if (user && userCourseAccess.length > 0) {
        try {
          const courseDetails = await Promise.all(
            userCourseAccess.map(access => getProduct(access.course_id))
          );
          setProducts(courseDetails.filter((course): course is Product => course !== null));
        } catch (err) {
          console.error('Failed to fetch products:', err);
        }
      }
    }
    fetchProducts();
  }, [user, userCourseAccess, getProduct]);

  if (productsLoading || accessLoading) return <div>Loading...</div>;
  if (productsError || accessError) return <div className="text-red-500">{((productsError || accessError) as Error).message}</div>;

  return (
    <div className="max-w-6xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Available Courses</h1>
      <ProductList products={products} />
    </div>
  );
}

export default Products;