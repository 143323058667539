import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { auth } from '../services/auth';
import { User, Session, AuthChangeEvent } from '@supabase/supabase-js';

interface AuthContextType {
  user: User | null;
  session: Session | null;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authState, setAuthState] = useState<AuthContextType>({
    user: null,
    session: null,
    loading: true
  });

  const handleAuthChange = useCallback(async (event: AuthChangeEvent, currentSession: Session | null) => {
    console.log('Auth state changed:', event);
    
    const newState: Partial<AuthContextType> = {
      session: currentSession,
      loading: false
    };

    if (currentSession?.user) {
      newState.user = currentSession.user;
      localStorage.setItem('supabase.auth.user', JSON.stringify(currentSession.user));
    } else {
      newState.user = null;
      localStorage.removeItem('supabase.auth.user');
    }

    setAuthState(prevState => ({ ...prevState, ...newState }));
  }, []);

  const initializeAuth = useCallback(async () => {
    const storedUser = localStorage.getItem('supabase.auth.user');
    const initialState: Partial<AuthContextType> = { loading: false };

    if (storedUser) {
      initialState.user = JSON.parse(storedUser);
    }

    const currentUser = await auth.getCurrentUser();
    if (currentUser) {
      initialState.user = currentUser;
      const currentSession = await auth.getSession();
      initialState.session = currentSession;
      localStorage.setItem('supabase.auth.user', JSON.stringify(currentUser));
    }

    setAuthState(prevState => ({ ...prevState, ...initialState }));
  }, []);

  useEffect(() => {
    initializeAuth();

    const { data: authListener } = auth.onAuthStateChange(handleAuthChange);

    return () => {
      if (authListener && 'subscription' in authListener) {
        authListener.subscription.unsubscribe();
      }
    };
  }, [handleAuthChange, initializeAuth]);

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
}

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}