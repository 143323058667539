import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BookOpen, HelpCircle, Activity, Layers, Award, FileText, User, ChevronRight, ChevronLeft, BarChart, Calendar, Search as SearchIcon, Menu } from 'react-feather';
import { useProducts } from '../components/ProductProvider';
import { useUserCourseAccess } from '../components/UserCourseAccessProvider';

interface SidebarProps {
  courseId?: string;
  user: any | null;
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}

interface MenuItem {
  path: string;
  icon: React.ElementType;
  label: string;
}

interface MenuSection {
  title?: string;
  items: MenuItem[];
}

interface Product {
  id: string;
  title: string;  
  description: string;
}

const Sidebar: React.FC<SidebarProps> = ({ courseId, user, isMenuOpen, setIsMenuOpen }) => {
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(() => {
    const saved = localStorage.getItem('sidebarExpanded');
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [sidebarWidth, setSidebarWidth] = useState(isExpanded ? 180 : 80);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1350);
  const [products, setProducts] = useState<Product[]>([]);
  const sidebarRef = useRef<HTMLDivElement>(null);  
  const { getProduct } = useProducts();
  const { userCourseAccess } = useUserCourseAccess();

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 1350;
      setIsMobile(mobile);
      if (mobile && isExpanded) {
        setIsExpanded(false);
      }
    };

    handleResize(); // Call once to set initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isExpanded]);

  useEffect(() => {
    setSidebarWidth(isExpanded ? 180 : 80);
    localStorage.setItem('sidebarExpanded', JSON.stringify(isExpanded));
  }, [isExpanded]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (user && userCourseAccess.length > 0) {
        try {
          const courseDetails = await Promise.all(
            userCourseAccess.map(access => getProduct(access.course_id))
          );
          setProducts(courseDetails.filter((course): course is Product => course !== null));
        } catch (error) {
          console.error('Failed to fetch courses:', error);
        }
      }
    };

    fetchProducts();
  }, [user, userCourseAccess, getProduct]);

  const menuSections: MenuSection[] = [
    {
      title: 'HOW To Pass',
      items: [
        { path: 'study-plan', icon: Calendar, label: 'Study Plan' },
        { path: 'mentor', icon: User, label: 'Mentor' },
      ],
    },
    {
      title: 'Learning Tools',
      items: [
        { path: 'lessons', icon: BookOpen, label: 'Lessons' },
        { path: 'quiz', icon: HelpCircle, label: 'Quiz' },
      ],
    },
    {
      title: 'Study Looping Tools',
      items: [
        { path: 'analytics', icon: BarChart, label: 'Analytics' },
        { path: 'kesler-cards', icon: Layers, label: 'KeslerCards' },
        { path: 'focus-notes', icon: FileText, label: 'Focus Notes' },
      ],
    },
    {
      title: 'Study Funnel Tools',
      items: [
        { path: 'simulations', icon: Activity, label: 'Simulations' },
        { path: 'mock-exam', icon: Award, label: 'Mock Exam' },
        { path: 'search', icon: SearchIcon, label: 'Search' },
      ],
    },
  ];

  const renderMobileMenu = () => (
    <div
      className={`fixed inset-0 z-50 transform ${
        isMenuOpen ? 'translate-x-0' : '-translate-x-full'
      } transition-transform duration-300 ease-in-out`}
    >
      <div className="absolute inset-0 bg-black opacity-50" onClick={() => setIsMenuOpen(false)}></div>
      <nav className="relative bg-white dark:bg-gray-800 w-64 h-full overflow-y-auto">
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Menu</h2>
          {courseId ? (
            menuSections.map((section, index) => (
              <div key={index} className="mb-4">
                <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400 mb-2">
                  {section.title}
                </h3>
                <ul>
                  {section.items.map((item) => {
                    const Icon = item.icon;
                    const isActive = location.pathname === `/course/${courseId}/${item.path}`;
                    return (
                      <li key={item.path}>
                        <Link
                          to={`/course/${courseId}/${item.path}`}
                          className={`flex items-center py-2 px-4 ${
                            isActive
                              ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-200'
                              : 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700'
                          }`}
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <Icon className="mr-3" size={18} />
                          {item.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))
          ) : (
            <div>
              <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400 mb-2">
                My Courses
              </h3>
              <ul>
                {products.map((product) => (
                  <li key={product.id}>
                    <Link
                      to={`/course/${product.id}/study-plan`}
                      className="flex items-center py-2 px-4 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <BookOpen className="mr-3" size={18} />
                      {product.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </div>
  );

  const renderDesktopSidebar = () => (
    <nav 
      ref={sidebarRef}
      className="sidebar fixed top-0 left-0 bottom-0 bg-white dark:bg-gray-800 pr-4 pl-4 pb-4 pt-2 overflow-y-auto transition-all duration-300 z-20"
      style={{ width: `${sidebarWidth}px` }}
    >
      <div className={`mb-2 ${isExpanded ? 'text-left' : 'text-center'}`}>
        <Link to="/products">
          <img
            src="/logo/mobile-app-png.png"
            alt="Kesler CPA Review Logo"
            className={`max-w-[45px] max-h-[45px] ${isExpanded ? 'w-full ml-0' : 'w-12 h-12 mx-auto'} transition-all duration-300 object-contain`}
          />
        </Link>
      </div>

      <ul className="space-y-4 flex-grow pt-1">
        {menuSections.map((section, index) => (
          <li key={index} className="space-y-2">
            {section.title && (
              <>
                {isExpanded ? (
                  <h3 className="text-xs font-semibold uppercase text-light-text dark:text-dark-text opacity-60 px-2 whitespace-nowrap">
                    {section.title}
                  </h3>
                ) : (
                  <div className="h-px bg-gray-300 dark:bg-gray-600 my-2" />
                )}
              </>
            )}
            <ul className="space-y-1">
              {section.items.map((item) => {
                const Icon = item.icon;
                const isActive = location.pathname === `/course/${courseId}/${item.path}`;
                return (
                  <li key={item.path} className="whitespace-nowrap">
                    <Link
                      to={`/course/${courseId}/${item.path}`}
                      className={`flex items-center ${isExpanded ? 'justify-start' : 'justify-center'} p-2 rounded-lg transition-colors ${
                        isActive
                          ? 'bg-primary-blue text-white dark:bg-dark-primary dark:text-dark-text'
                          : 'text-light-text hover:bg-dark-primary hover:text-white dark:text-dark-text dark:hover:bg-primary-blue'
                      } ${!isExpanded ? 'w-12 h-12' : ''}`}
                    >
                      <Icon className={isExpanded ? 'mr-2' : ''} size={20} />
                      {isExpanded && <span className="text-sm">{item.label}</span>}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
        ))}
        <li>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={`w-full flex items-center justify-center p-2 bg-primary-blue text-white rounded-lg hover:bg-primary-blue-hover transition-colors ${
              isExpanded ? 'mt-2' : 'mt-1'
            }`}
          >
            {isExpanded ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
          </button>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      {isMobile ? renderMobileMenu() : courseId && renderDesktopSidebar()}
    </>
  );
};

export default Sidebar;