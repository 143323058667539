import React, { createContext, useContext, useEffect, useState } from 'react';
import { flashcardIDB } from '../services/flashcardapi';
import { useParams } from 'react-router-dom';

interface FlashcardDBContextType {
  isInitialized: boolean;
  courseId: string | null;
}

const FlashcardDBContext = createContext<FlashcardDBContextType | undefined>(undefined);

export function FlashcardDBProvider({ children }: { children: React.ReactNode }) {
  const [isInitialized, setIsInitialized] = useState(false);
  const { courseId } = useParams<{ courseId: string }>();
  const [currentCourseId, setCurrentCourseId] = useState<string | null>(null);

  useEffect(() => {
    const initializeDB = async () => {
      try {
        if (courseId) {
          await flashcardIDB.init(courseId);
          setIsInitialized(true);
          setCurrentCourseId(courseId);
        } else {
          // Fallback to initialize without courseId to maintain existing functionality
          await flashcardIDB.init();
          setIsInitialized(true);
        }
      } catch (error) {
        console.error('Failed to initialize FlashcardDB:', error);
      }
    };

    initializeDB();
  }, [courseId]);

  return (
    <FlashcardDBContext.Provider value={{ isInitialized, courseId: currentCourseId }}>
      {children}
    </FlashcardDBContext.Provider>
  );
}

export function useFlashcardDB() {
  const context = useContext(FlashcardDBContext);
  if (context === undefined) {
    throw new Error('useFlashcardDB must be used within a FlashcardDBProvider');
  }
  return context;
}