import { supabase } from './supabase';
import { openDB, DBSchema, IDBPDatabase, IDBPTransaction, IDBPObjectStore } from 'idb';
import { Flashcard, FlashcardCategory, UserFlashcardProgress, CustomFlashcard } from '../services/api';

interface FlashcardDBSchema extends DBSchema {
    flashcards: {
      key: string;
      value: Flashcard;
      indexes: { 'by-category': string };
    };
    categories: {
      key: string;
      value: FlashcardCategory & { sequence_number: number }; // Add sequence_number here
    };
    metaData: {
        key: string;
        value: string;
        };
    userProgress: {
        key: string;
        value: UserFlashcardProgress;
        indexes: { 
            'by-flashcard': string;
            'by-user': string;
            'by-course': string;
            'synced': number;
        };
    };
    customFlashcards: {
      key: string; // This will be the card_identifier
      value: CustomFlashcard;
      indexes: { 
        'by-user': string;
        'by-course': string;
        'by-tag': string[];
        'synced': number;
        'by-local-id': string; // Add this index
      };
  };
    deletedCustomFlashcards: {
        key: string;
        value: { id: string; course_id: string };
      };
      userStudyStats: {
        key: string;
        value: {
            id: string;
            cardsCompleted: number;
        };
        };
        userTags: {
            key: string;
            value: {
                id: string;
                user_id: string;
                course_id: string;
                tags: string[];
            };
            indexes: { 'by-user-course': [string, string] };
        };
    }

    class FlashcardIDBWrapper {
        private db!: IDBPDatabase<FlashcardDBSchema>;
    
        async init(courseId?: string) {
            try {
                this.db = await openDB<FlashcardDBSchema>('flashcardDB', 11, {
                    upgrade(db: IDBPDatabase<FlashcardDBSchema>, oldVersion, newVersion, transaction: IDBPTransaction<FlashcardDBSchema, ("flashcards" | "categories" | "userProgress" | "customFlashcards" | "deletedCustomFlashcards" | "userStudyStats" | "metaData" | "userTags")[], "versionchange">) {
                        console.log(`Upgrading database from version ${oldVersion} to ${newVersion}`);
                        
                        if (oldVersion < 1) {
                            // Create initial object stores
                            if (!db.objectStoreNames.contains('flashcards')) {
                                const flashcardsStore = db.createObjectStore('flashcards', { keyPath: 'id' });
                                flashcardsStore.createIndex('by-category', 'category_id');
                            }
                            
                            if (!db.objectStoreNames.contains('categories')) {
                                db.createObjectStore('categories', { keyPath: 'id' });
                            }
                            
                            if (!db.objectStoreNames.contains('customFlashcards')) {
                                const customFlashcardsStore = db.createObjectStore('customFlashcards', { keyPath: 'id' });
                                customFlashcardsStore.createIndex('by-user', 'user_id');
                                customFlashcardsStore.createIndex('by-tag', 'tags', { multiEntry: true });
                                customFlashcardsStore.createIndex('synced', 'synced');
                            }
                        }
                
                        if (oldVersion < 2) {
                            // Add or update userProgress store
                            let userProgressStore: IDBPObjectStore<FlashcardDBSchema, (typeof transaction.objectStoreNames[number])[], "userProgress", "versionchange">;
                            if (!db.objectStoreNames.contains('userProgress')) {
                                userProgressStore = db.createObjectStore('userProgress', { keyPath: 'id' });
                            } else {
                                userProgressStore = transaction.objectStore('userProgress');
                            }
                    
                            // Ensure all required indexes exist
                            const requiredIndexes = ['synced', 'by-flashcard', 'by-user'] as const;
                            requiredIndexes.forEach((indexName) => {
                                if (!userProgressStore.indexNames.contains(indexName)) {
                                    userProgressStore.createIndex(indexName, indexName === 'synced' ? 'synced' : indexName.replace('by-', '') as 'flashcard' | 'user');
                                }
                            });
                        }
        
                        if (oldVersion < 7) {
                            if (db.objectStoreNames.contains('deletedCustomFlashcards')) {
                                db.deleteObjectStore('deletedCustomFlashcards');
                            }
                            db.createObjectStore('deletedCustomFlashcards', { keyPath: 'id' });
                        }
        
                        if (oldVersion < 8) {
                            if (!db.objectStoreNames.contains('userStudyStats')) {
                                db.createObjectStore('userStudyStats', { keyPath: 'id' });
                            }
                        }
        
                        if (oldVersion < 9) {
                            if (!db.objectStoreNames.contains('metaData')) {
                                db.createObjectStore('metaData');
                            }
                        }
                        if (oldVersion < 10) {
                            if (!db.objectStoreNames.contains('userTags')) {
                                const userTagsStore = db.createObjectStore('userTags', { keyPath: 'id' });
                                userTagsStore.createIndex('by-user-course', ['user_id', 'course_id'], { unique: true });
                            }
                        }
                        if (oldVersion < 11) {
                          // Add 'by-course' index to userProgress and customFlashcards
                          const userProgressStore = transaction.objectStore('userProgress');
                          if (!userProgressStore.indexNames.contains('by-course')) {
                              userProgressStore.createIndex('by-course', 'course_id');
                          }
                      }
                      if (oldVersion < 12) {
                          // Update customFlashcards store
                          if (db.objectStoreNames.contains('customFlashcards')) {
                              db.deleteObjectStore('customFlashcards');
                          }
                          const customFlashcardsStore = db.createObjectStore('customFlashcards', { keyPath: 'card_identifier' });
                          customFlashcardsStore.createIndex('by-user', 'user_id');
                          customFlashcardsStore.createIndex('by-course', 'course_id');
                          customFlashcardsStore.createIndex('by-tag', 'tags', { multiEntry: true });
                          customFlashcardsStore.createIndex('synced', 'synced');
                          customFlashcardsStore.createIndex('by-local-id', 'local_id');
                      }
                  },
              });
              console.log('Database initialized successfully');
              
              if (courseId) {
                  console.log(`Initialized with courseId: ${courseId}`);
                  // You can add any course-specific initialization logic here if needed
              }
          } catch (error) {
              console.error('Error initializing database:', error);
              throw error;
          }
      }

    //STALE DATA CHECK
    async getLastUpdatedTimestamp(table: 'flashcards' | 'categories'): Promise<string> {
        const timestamp = await this.db.get('metaData', `${table}_last_updated`);
        return timestamp || '1970-01-01T00:00:00Z';
      }
      
      async setLastUpdatedTimestamp(table: 'flashcards' | 'categories', timestamp: string): Promise<void> {
        await this.db.put('metaData', timestamp, `${table}_last_updated`);
      }
      
  // CRUD operations for flashcards
  async getFlashcard(id: string): Promise<Flashcard | undefined> {
    return this.db.get('flashcards', id);
  }

  async addFlashcard(flashcard: Flashcard): Promise<string> {
    return this.db.add('flashcards', flashcard);
  }

  async updateFlashcard(flashcard: Flashcard): Promise<string> {
    return this.db.put('flashcards', flashcard);
  }
 
  async getCustomFlashcard(cardIdentifier: string): Promise<CustomFlashcard | undefined> {
    return this.db.get('customFlashcards', cardIdentifier);
}

  async getAllCategories(): Promise<FlashcardCategory[]> {
    return this.db.getAll('categories');
  }

  async getAllCustomFlashcards(): Promise<CustomFlashcard[]> {
    return this.db.getAll('customFlashcards');
  }

  async getDeletedCustomFlashcards(): Promise<Array<{ id: string; course_id: string }>> {
    try {
      const deletedCards = await this.db.getAll('deletedCustomFlashcards');
      return deletedCards;
    } catch (error) {
      console.error('Error getting deleted custom flashcards:', error);
      throw error;
    }
  }

  async getAllFlashcards(): Promise<Flashcard[]> {
    return this.db.getAll('flashcards');
  }

  async getFlashcardsByCategory(categoryId: string): Promise<Flashcard[]> {
    return this.db.getAllFromIndex('flashcards', 'by-category', categoryId);
  }

  async addCategory(category: FlashcardCategory): Promise<string> {
    return this.db.add('categories', category);
  }

  async addUserProgress(progress: Omit<UserFlashcardProgress, 'id' | 'synced'>): Promise<string> {
    const newProgress: UserFlashcardProgress = {
      ...progress,
      id: `${progress.user_id}-${progress.course_id}-${progress.flashcard_id}-${progress.browser_instance_id}`,
      synced: 0
    };
    await this.db.put('userProgress', newProgress);
    return newProgress.id;
  }
  
  async addOrUpdateUserProgress(localId: string, progress: Omit<UserFlashcardProgress, 'id' | 'synced'>): Promise<void> {
    const existingProgress = await this.db.get('userProgress', localId);
    const updatedProgress: UserFlashcardProgress = {
        ...progress,
        id: localId,
        synced: 0,
        version: (existingProgress?.version || 0) + 1,
        last_reviewed: new Date().toISOString(),
    };
    await this.db.put('userProgress', updatedProgress);
}

  async getUserFlashcardProgress(userId: string, courseId: string): Promise<UserFlashcardProgress[]> {
    return this.db.getAllFromIndex('userProgress', 'by-course', courseId)
        .then(progress => progress.filter(p => p.user_id === userId));
}

  async getUserStudyStats(userId: string, courseId: string): Promise<{ cardsCompleted: number }> {
    const key = `${userId}-${courseId}`;
    const storedStats = await this.db.get('userStudyStats', key);
    
    if (storedStats) {
        return { cardsCompleted: storedStats.cardsCompleted };
    } else {
        const premadeCount = (await this.db.getAllFromIndex('userProgress', 'by-user', userId))
            .filter(progress => progress.course_id === courseId).length;
        const customCount = (await this.getCustomFlashcards(userId, courseId)).length;
        const cardsCompleted = premadeCount + customCount;
        
        // Store the calculated stats
        await this.updateUserStudyStats(userId, courseId, cardsCompleted);
        
        return { cardsCompleted };
    }
}

async updateUserStudyStats(userId: string, courseId: string, cardsCompleted: number): Promise<void> {
    const key = `${userId}-${courseId}`;
    await this.db.put('userStudyStats', { id: key, cardsCompleted });
}

  
async getAllUserProgress(courseId: string): Promise<UserFlashcardProgress[]> {
    return this.db.getAllFromIndex('userProgress', 'by-course', courseId);
  }

  async markProgressAsSynced(id: string): Promise<void> {
    const progress = await this.db.get('userProgress', id);
    if (progress) {
      progress.synced = 1;
      await this.db.put('userProgress', progress);
    }
  }

  async markProgressAsUnsynced(id: string): Promise<void> {
    const progress = await this.db.get('userProgress', id);
    if (progress) {
      progress.synced = 0;
      await this.db.put('userProgress', progress);
    }
  }

  async getUserProgress(userId: string, courseId: string, flashcardId: string): Promise<UserFlashcardProgress | undefined> {
    return this.db.get('userProgress', `${userId}-${courseId}-${flashcardId}`);
  }

  // ADD OR UPDATE
  async addOrUpdateFlashcard(flashcard: Flashcard): Promise<void> {
    await this.db.put('flashcards', flashcard);
  }

  async addOrUpdateCategory(category: FlashcardCategory): Promise<void> {
    await this.db.put('categories', category);
  }

  async getCustomFlashcardByAnyId(id: string): Promise<CustomFlashcard | undefined> {
    // Try to get by local_id
    let card = await this.db.getFromIndex('customFlashcards', 'by-local-id', id);
    if (card) return card;
  
    // If not found, try to get by card_identifier
    card = await this.db.get('customFlashcards', id);
    if (card) return card;
  
    // If still not found, try to get by Supabase ID
    const allCards = await this.db.getAll('customFlashcards');
    return allCards.find(c => c.id === id);
  }
  
  async addOrUpdateCustomFlashcard(flashcard: CustomFlashcard): Promise<void> {
    const existingCard = await this.getCustomFlashcardByAnyId(flashcard.local_id || flashcard.card_identifier || flashcard.id);
    if (existingCard) {
      // Update existing card
      await this.db.put('customFlashcards', {
        ...existingCard,
        ...flashcard,
        card_identifier: existingCard.card_identifier, // Ensure we keep the original card_identifier
        local_id: existingCard.local_id // Ensure we keep the original local_id
      });
    } else {
      // Add new card
      await this.db.put('customFlashcards', flashcard);
    }
  }

async getCustomFlashcardByLocalId(localId: string): Promise<CustomFlashcard | undefined> {
    return this.db.getFromIndex('customFlashcards', 'by-local-id', localId);
}

  async addCustomFlashcard(flashcard: CustomFlashcard): Promise<string> {
      await this.db.put('customFlashcards', flashcard);
      return flashcard.card_identifier;
  }


  async add<T extends 'flashcards' | 'categories' | 'userProgress' | 'customFlashcards'>(
    storeName: T,
    value: FlashcardDBSchema[T]['value']
  ): Promise<IDBValidKey> {
    return this.db.add(storeName, value);
  }

  async updateCustomFlashcard(flashcard: CustomFlashcard): Promise<void> {
    const existingCard = await this.getCustomFlashcardByAnyId(flashcard.local_id || flashcard.card_identifier || flashcard.id);
    if (!existingCard) {
      throw new Error('Custom flashcard not found');
    }
    const updatedFlashcard = {
      ...existingCard,
      ...flashcard,
      updated_at: new Date().toISOString(),
      version: (existingCard.version || 0) + 1,
      synced: 0
    };
    await this.db.put('customFlashcards', updatedFlashcard);
  }

  async getCustomFlashcards(
    userId: string, 
    courseId: string, 
    filters?: { 
        tags?: string[], 
        confidenceLevel?: string,
        lastReviewedBefore?: Date
    }
): Promise<CustomFlashcard[]> {
    let cards = await this.db.getAllFromIndex('customFlashcards', 'by-course', courseId);
    cards = cards.filter(card => card.user_id === userId);

    if (filters?.tags && filters.tags.length > 0) {
        cards = cards.filter(card => filters.tags!.every(tag => card.tags.includes(tag)));
    }

    if (filters?.confidenceLevel) {
        cards = cards.filter(card => card.confidence_level === filters.confidenceLevel);
    }

    if (filters?.lastReviewedBefore) {
        cards = cards.filter(card => new Date(card.last_reviewed) < filters.lastReviewedBefore!);
    }

    return cards.sort((a, b) => new Date(a.last_reviewed).getTime() - new Date(b.last_reviewed).getTime());
}

  async updateUserFlashcardProgress(progress: Omit<UserFlashcardProgress, 'id'>): Promise<UserFlashcardProgress> {
    const id = `${progress.user_id}-${progress.course_id}-${progress.flashcard_id}`;
    const updatedProgress = { ...progress, id };
    await this.db.put('userProgress', updatedProgress);
    return updatedProgress;
  }

  async getAllUserTags(userId: string, courseId: string): Promise<string[]> {
    const key = `${userId}-${courseId}`;
    const userTags = await this.db.getFromIndex('userTags', 'by-user-course', [userId, courseId]);
    return userTags?.tags || [];
}

async addUserTags(userId: string, courseId: string, newTags: string[]): Promise<void> {
    const key = `${userId}-${courseId}`;
    const existingEntry = await this.db.getFromIndex('userTags', 'by-user-course', [userId, courseId]);
    const updatedTags = Array.from(new Set([...(existingEntry?.tags || []), ...newTags]));
    
    await this.db.put('userTags', {
        id: key,
        user_id: userId,
        course_id: courseId,
        tags: updatedTags
    });
}
  
  async clearAll(): Promise<void> {
    await this.db.clear('flashcards');
    await this.db.clear('categories');
    await this.db.clear('userProgress');
    await this.db.clear('customFlashcards');
    console.log('All IndexedDB data cleared');
}

async getCustomFlashcardsByTag(tag: string): Promise<CustomFlashcard[]> {
    return this.db.getAllFromIndex('customFlashcards', 'by-tag', [tag]);
}

  // Method to get unsynced custom flashcards
  async getUnsyncedCustomFlashcards(): Promise<CustomFlashcard[]> {
    return this.db.getAllFromIndex('customFlashcards', 'synced', 0);
  }

  // Method to mark custom flashcard as synced
  async markCustomFlashcardAsSynced(id: string): Promise<void> {
    const flashcard = await this.db.get('customFlashcards', id);
    if (flashcard) {
      flashcard.synced = 1;
      await this.db.put('customFlashcards', flashcard);
    }
  }

// DELETE CUSTOM CARD SECTION

  async getCustomFlashcardByIdentifier(identifier: string): Promise<CustomFlashcard | undefined> {
    // Try to get by card_identifier first
    let card = await this.db.get('customFlashcards', identifier);
    if (!card) {
        // If not found, try to get by local_id
        card = await this.db.getFromIndex('customFlashcards', 'by-local-id', identifier);
    }
    if (!card) {
        // If still not found, try to get by Supabase ID
        const allCards = await this.db.getAll('customFlashcards');
        card = allCards.find(c => c.id === identifier);
    }
    return card;
  }

  async deleteCustomFlashcard(identifier: string, courseId: string): Promise<void> {
    const tx = this.db.transaction(['customFlashcards', 'deletedCustomFlashcards', 'userTags'], 'readwrite');
    const customFlashcardsStore = tx.objectStore('customFlashcards');
    const deletedCustomFlashcardsStore = tx.objectStore('deletedCustomFlashcards');
    const userTagsStore = tx.objectStore('userTags');
  
    try {
      const card = await customFlashcardsStore.get(identifier);
      if (!card) {
        console.log(`Custom flashcard with identifier ${identifier} not found, skipping deletion`);
        return;
      }
  
      // Delete the card
      await customFlashcardsStore.delete(identifier);
      await deletedCustomFlashcardsStore.add({ id: card.id, course_id: courseId });
  
      // Update tags
      const userTags = await userTagsStore.get(`${card.user_id}-${courseId}`);
      if (userTags) {
        const remainingCards = await customFlashcardsStore.index('by-user').getAll(card.user_id);
        const remainingTags = new Set(remainingCards.flatMap(c => c.tags));
        userTags.tags = Array.from(remainingTags);
        await userTagsStore.put(userTags);
      }
  
      await tx.done;
      console.log(`Custom flashcard with identifier ${identifier} successfully marked for deletion and tags updated`);
    } catch (error) {
      console.error('Error during custom flashcard deletion:', error);
      throw error;
    }
  }

  async getDeletedCustomFlashcardIds(): Promise<string[]> {
    try {
      const deletedCards = await this.getDeletedCustomFlashcards();
      return deletedCards.map(card => card.id);
    } catch (error) {
      console.error('Error getting deleted custom flashcard IDs:', error);
      throw error;
    }
  }

  async removeDeletedCustomFlashcardId(id: string): Promise<void> {
    try {
      await this.db.delete('deletedCustomFlashcards', id);
      console.log(`Deleted custom flashcard ID ${id} removed from tracking`);
    } catch (error) {
      console.error('Error removing deleted custom flashcard ID:', error);
      throw error;
    }
  }

}

export const flashcardIDB = new FlashcardIDBWrapper();















// First, update the type definition for sync queue items
type SyncQueueItem = {
  type: 'customFlashcard' | 'userProgress';
  data: any;
  tempLocalId?: string;
  cardIdentifier?: string; // Add this for custom flashcards
};

class SyncService {
    private isSyncing: boolean = false;
    private syncInterval: number = 5 * 60 * 1000; // 5 minutes
    private batchSize: number = 1000; // Increased batch size for Supabase
    private syncQueue: SyncQueueItem[] = []; // Update the type here
    private lastSyncTime: number = 0;
    private retryDelay: number = 1000;
    private maxRetries: number = 3;
  
    constructor() {
      this.initPeriodicSync();
      setInterval(() => this.cleanupSyncQueue(), 30 * 60 * 1000); // Clean up every 30 minutes
    }
  
    private initPeriodicSync() {
      setInterval(() => this.syncAll(), this.syncInterval);
    }
  
    async syncAll() {
        if (this.isSyncing) {
            console.log('Sync already in progress, skipping');
            return;
        }

        this.isSyncing = true;

        try {
            console.log('Starting syncAll');
            await this.syncWithRetry(() => this.syncDeletedCustomFlashcards());
            await this.syncWithRetry(() => this.syncCustomFlashcards());
            await this.syncWithRetry(() => this.syncUserProgress());
            await this.processQueue();
            console.log('syncAll completed');
            this.lastSyncTime = Date.now();
        } catch (error) {
            console.error('Error during sync:', error);
        } finally {
            this.isSyncing = false;
        }
    }
  
    private async syncWithRetry(syncFunction: () => Promise<void>, retries: number = 0) {
        try {
            await syncFunction();
        } catch (error) {
            if (retries < this.maxRetries) {
                console.log(`Sync failed, retrying in ${this.retryDelay / 1000} seconds...`);
                await new Promise(resolve => setTimeout(resolve, this.retryDelay));
                await this.syncWithRetry(syncFunction, retries + 1);
            } else {
                console.error('Max retries reached, sync failed');
                throw error;
            }
        }
    }

    private async processQueue() {
      if (this.isSyncing) return;
      this.isSyncing = true;

      const customFlashcards: CustomFlashcard[] = [];
      const userProgress: UserFlashcardProgress[] = [];

      while (this.syncQueue.length > 0) {
          const item = this.syncQueue.shift();
          if (!item) break;

          if (item.type === 'customFlashcard') {
              customFlashcards.push(item.data);
          } else if (item.type === 'userProgress') {
              userProgress.push(item.data);
          }

          if (customFlashcards.length >= this.batchSize) {
              await this.batchUpdateServerCustomFlashcards(customFlashcards);
              customFlashcards.length = 0;
          }

          if (userProgress.length >= this.batchSize) {
              await this.batchUpsertUserProgress(userProgress);
              await this.markUserProgressAsSynced(userProgress);
              userProgress.length = 0;
          }
      }

      // Process any remaining items
      if (customFlashcards.length > 0) {
          await this.batchUpdateServerCustomFlashcards(customFlashcards);
      }
      if (userProgress.length > 0) {
          await this.batchUpsertUserProgress(userProgress);
          await this.markUserProgressAsSynced(userProgress);
      }

      this.isSyncing = false;
  }

    //BATCHING

    private async batchUpsertUserProgress(progressItems: UserFlashcardProgress[]) {
        const { error } = await supabase
            .from('user_flashcard_progress')
            .upsert(progressItems.map(({ id, ...item }) => ({
                ...item,
                synced: true
            })), {
                onConflict: 'user_id,course_id,flashcard_id,browser_instance_id',
                ignoreDuplicates: false
            });

        if (error) throw error;

        // We don't need to mark items as synced here anymore, as it's done in processQueue
    }

    private async batchDeleteServerCustomFlashcards(items: Array<{ id: string; course_id: string }>) {
        const { error } = await supabase
          .from('custom_flashcards')
          .delete()
          .in('id', items.map(item => item.id))
          .in('course_id', items.map(item => item.course_id));
    
        if (error) throw error;
    
        // Remove deleted IDs from local storage
        await Promise.all(items.map(item => flashcardIDB.removeDeletedCustomFlashcardId(item.id)));
      }

      private async batchUpdateServerCustomFlashcards(cards: CustomFlashcard[]): Promise<CustomFlashcard[]> {
        const { data, error } = await supabase
            .from('custom_flashcards')
            .upsert(cards.map(({ id, ...card }) => ({
                ...card,
                // Include local_id in the upsert operation
            })), {
                onConflict: 'card_identifier,user_id,course_id,browser_instance_id'
            });

        if (error) throw error;

        // Fetch the updated cards from Supabase
        const { data: updatedCards, error: fetchError } = await supabase
            .from('custom_flashcards')
            .select('*')
            .in('card_identifier', cards.map(card => card.card_identifier));

        if (fetchError) throw fetchError;

        if (updatedCards && Array.isArray(updatedCards)) {
            for (const serverCard of updatedCards) {
                if (serverCard && serverCard.id) {
                    // Find the corresponding local card using local_id
                    const localCard = await flashcardIDB.getCustomFlashcardByLocalId(serverCard.local_id);
                    if (localCard) {
                        // Update the local card with the server-generated ID and other fields
                        await flashcardIDB.addOrUpdateCustomFlashcard({
                            ...localCard,
                            ...serverCard,
                            synced: 1
                        });
                    } else {
                        console.error(`Local card not found for local_id: ${serverCard.local_id}`);
                    }
                }
            }
        }

        return updatedCards || [];
    }

    private async batchSyncUserProgress(progressBatch: UserFlashcardProgress[]) {
        for (const localItem of progressBatch) {
            let retries = 3;
            while (retries > 0) {
                try {
                    if (
                        !this.isValidUUID(localItem.user_id) ||
                        !this.isValidUUID(localItem.course_id) ||
                        !this.isValidUUID(localItem.flashcard_id) ||
                        !this.isValidUUID(localItem.browser_instance_id)
                    ) {
                        console.error('Invalid UUID detected in user progress item', localItem);
                        break; // Skip this item
                    }

                    // Remove the 'id' field before upserting
                    const { id, ...itemToUpsert } = localItem;

                    const { error } = await supabase
                        .from('user_flashcard_progress')
                        .upsert({ ...itemToUpsert, synced: true }, {
                            onConflict: 'user_id,course_id,flashcard_id,browser_instance_id'
                        });

                    if (error) throw error;

                    // If successful, mark this item as synced in IndexedDB
                    await flashcardIDB.markProgressAsSynced(localItem.id);
                    break; // Success, exit retry loop
                } catch (error) {
                    console.error(`Error syncing user progress to server (attempt ${4 - retries}/3):`, error);
                    retries--;
                    if (retries === 0) {
                        console.error('Failed to sync item after 3 attempts:', localItem);
                    } else {
                        // Wait before retrying
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    }
                }
            }

            // Add a small delay between requests
            await new Promise(resolve => setTimeout(resolve, 100));
        }
    }

    //END SYNC
    private async markCustomFlashcardsAsSynced(cards: CustomFlashcard[]) {
      for (const card of cards) {
          if (card.id) {
              await flashcardIDB.markCustomFlashcardAsSynced(card.id);
          }
      }
  }

    private async markUserProgressAsSynced(progress: UserFlashcardProgress[]) {
        for (const item of progress) {
            const localId = `${item.user_id}-${item.course_id}-${item.flashcard_id}-${item.browser_instance_id}`;
            await flashcardIDB.markProgressAsSynced(localId);
        }
    }
  
    async syncCustomFlashcards() {
      const localCards = await flashcardIDB.getAllCustomFlashcards();
      const deletedCards = await flashcardIDB.getDeletedCustomFlashcards();
      const { data: serverCards, error } = await supabase
          .from('custom_flashcards')
          .select('*');
  
      if (error) throw error;
  
      // Handle deletions in batches
      for (let i = 0; i < deletedCards.length; i += this.batchSize) {
          const batch = deletedCards.slice(i, i + this.batchSize);
          await this.batchDeleteServerCustomFlashcards(batch);
      }
  
      // Handle updates and additions
      const cardsToUpdate = localCards.filter(localCard => 
          !serverCards.find(c => c.id === localCard.id) || 
          localCard.version > (serverCards.find(c => c.id === localCard.id)?.version || 0)
      );
  
      for (let i = 0; i < cardsToUpdate.length; i += this.batchSize) {
          const batch = cardsToUpdate.slice(i, i + this.batchSize);
          await this.batchUpdateServerCustomFlashcards(batch);
          // Mark updated cards as synced
          await this.markCustomFlashcardsAsSynced(batch);
      }
  
      // Update local storage with server data
      if (serverCards) {
          for (const serverCard of serverCards) {
              const localCard = localCards.find(c => c.id === serverCard.id);
              if (!localCard || serverCard.version > localCard.version) {
                  await flashcardIDB.addOrUpdateCustomFlashcard(serverCard);
                  // Mark newly added or updated card as synced
                  await flashcardIDB.markCustomFlashcardAsSynced(serverCard.id);
              }
          }
      }
  }

    private isValidUUID(uuid: string): boolean {
        const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return regex.test(uuid);
    }
  
    async syncUserProgress() {
        // Get all unique course IDs from the sync queue
        const courseIds = [...new Set(this.syncQueue
          .filter(item => item.type === 'userProgress')
          .map(item => item.data.course_id)
        )];
      
        for (const courseId of courseIds) {
          const localProgress = await flashcardIDB.getAllUserProgress(courseId);
          const unsyncedProgress = localProgress.filter(progress => progress.synced === 0);
          
          for (let i = 0; i < unsyncedProgress.length; i += this.batchSize) {
            const batch = unsyncedProgress.slice(i, i + this.batchSize);
            await this.batchSyncUserProgress(batch);
          }
        }
      }

      async addToSyncQueue(type: 'customFlashcard' | 'userProgress', data: any) {
        try {
            if (type === 'customFlashcard') {
                const cardIdentifier = data.card_identifier;
                
                // Store the card in IndexedDB
                await flashcardIDB.addOrUpdateCustomFlashcard(data);
                
                // Only add to queue if not already synced
                const existingCard = await flashcardIDB.getCustomFlashcardByLocalId(data.local_id);
                if (!existingCard || !existingCard.synced) {
                    this.syncQueue.push({ type, data, cardIdentifier, tempLocalId: data.local_id });
                }
            } else if (type === 'userProgress') {
                const localId = `${data.user_id}-${data.course_id}-${data.flashcard_id}-${data.browser_instance_id}`;
                await flashcardIDB.addOrUpdateUserProgress(localId, data);
                // Only add to queue if not already synced
                const existingProgress = await flashcardIDB.getUserProgress(data.user_id, data.course_id, data.flashcard_id);
                if (!existingProgress || existingProgress.synced === 0) {
                    this.syncQueue.push({ type, data: { ...data, id: localId }, tempLocalId: localId });
                }
            }

            if (navigator.onLine) {
              await this.processQueue();
          }
      } catch (error) {
          console.error('Error adding to sync queue:', error);
      }
  }
  
  private generateCardIdentifier(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  private generateLocalId(userId: string, courseId: string): string {
    return `local_${Date.now()}_${userId}_${courseId}`;
  }

  async createCustomFlashcard(flashcard: Omit<CustomFlashcard, 'id' | 'created_at' | 'updated_at' | 'last_reviewed' | 'version' | 'synced' | 'card_identifier' | 'local_id'>): Promise<CustomFlashcard> {
    const now = new Date().toISOString();
    const cardIdentifier = this.generateCardIdentifier();
    const localId = this.generateLocalId(flashcard.user_id, flashcard.course_id);

    const newCard: CustomFlashcard = {
      ...flashcard,
      id: '', // This will be set by Supabase later
      card_identifier: cardIdentifier,
      local_id: localId,
      created_at: now,
      updated_at: now,
      last_reviewed: now,
      version: 1,
      synced: 0
    };

    try {
      // Add the new card to IndexedDB
      await flashcardIDB.addCustomFlashcard(newCard);

      // Add the card to the sync queue
      await this.addToSyncQueue('customFlashcard', newCard);

      // Update user tags in IDB
      if (newCard.tags && newCard.tags.length > 0) {
        await flashcardIDB.addUserTags(newCard.user_id, newCard.course_id, newCard.tags);
      }

      return newCard;
    } catch (error) {
      console.error('Error creating custom flashcard:', error);
      throw new Error('Failed to create custom flashcard');
    }
  }
  
  async updateCustomFlashcard(id: string, updates: Partial<CustomFlashcard>) {
    const existingCard = await flashcardIDB.getCustomFlashcardByAnyId(id);
    if (!existingCard) {
      throw new Error('Custom flashcard not found');
    }
  
    const updatedCard = {
      ...existingCard,
      ...updates,
      updated_at: new Date().toISOString(),
      version: (existingCard.version || 0) + 1,
      synced: 0
    };
  
    await flashcardIDB.updateCustomFlashcard(updatedCard);
    await this.addToSyncQueue('customFlashcard', updatedCard);
    return updatedCard;
  }
  
    async updateUserFlashcardProgress(progress: Omit<UserFlashcardProgress, 'id'>) {
        try {
            const localId = `${progress.user_id}-${progress.course_id}-${progress.flashcard_id}-${progress.browser_instance_id}`;
            
            // Update in IndexedDB
            await flashcardIDB.addOrUpdateUserProgress(localId, progress);
            
            // Add to sync queue for batch processing
            await this.addToSyncQueue('userProgress', { ...progress, id: localId });
            
            // If we have enough items in the queue or it's been a while since last sync, trigger a sync
            if (this.syncQueue.length >= this.batchSize || Date.now() - this.lastSyncTime > this.syncInterval) {
                await this.processQueue();
            }
            
            console.log(`User flashcard progress with id ${localId} queued for update`);
            return { ...progress, id: localId };
        } catch (error) {
            console.error('Error updating user flashcard progress:', error);
            throw error;
        }
    }

      async getUserFlashcardProgress(userId: string, courseId: string): Promise<UserFlashcardProgress[]> {
        try {
            // First, get progress from IndexedDB
            const localProgress = await flashcardIDB.getUserFlashcardProgress(userId, courseId);
            
            // Then, try to fetch from Supabase and update local storage
            const { data, error } = await supabase
                .from('user_flashcard_progress')
                .select('*')
                .eq('user_id', userId)
                .eq('course_id', courseId);

            if (error) throw error;

            if (data) {
                // Update local storage with Supabase data
                for (const item of data) {
                    const localId = `${item.user_id}-${item.course_id}-${item.flashcard_id}-${item.browser_instance_id}`;
                    await flashcardIDB.addOrUpdateUserProgress(localId, item);
                }
                return data;
            }

            return localProgress;
        } catch (error) {
            console.error('Error fetching user flashcard progress:', error);
            // If there's an error fetching from Supabase, return local data
            return flashcardIDB.getUserFlashcardProgress(userId, courseId);
        }
    }

    async getCustomFlashcards(userId: string, courseId: string, filters?: { 
        tags?: string[], 
        confidenceLevel?: string,
        lastReviewedBefore?: Date
    }): Promise<CustomFlashcard[]> {
        try {
            // Construct a query with server-side filtering
            let query = supabase
                .from('custom_flashcards')
                .select('*')
                .eq('user_id', userId)
                .eq('course_id', courseId);
    
            // Apply filters on the server side when possible
            if (filters?.tags && filters.tags.length > 0) {
                query = query.contains('tags', filters.tags);
            }
            if (filters?.confidenceLevel) {
                query = query.eq('confidence_level', filters.confidenceLevel);
            }
            if (filters?.lastReviewedBefore) {
                query = query.lt('last_reviewed', filters.lastReviewedBefore.toISOString());
            }
    
            // Fetch data from Supabase with applied filters
            const { data, error } = await query;
    
            if (error) throw error;
    
            if (data) {
                // Update local storage with Supabase data
                await Promise.all(data.map(card => flashcardIDB.addOrUpdateCustomFlashcard(card)));
                
                // No need to apply filters here as they were applied server-side
                return data;
            }
    
            // If Supabase fetch fails or returns no data, get custom flashcards from IndexedDB
            return flashcardIDB.getCustomFlashcards(userId, courseId, filters);
        } catch (error) {
            console.error('Error fetching custom flashcards:', error);
            // If there's an error fetching from Supabase, get custom flashcards from IndexedDB
            return flashcardIDB.getCustomFlashcards(userId, courseId, filters);
        }
    }

    async getUserStudyStats(userId: string, courseId: string): Promise<{ cardsCompleted: number }> {
        try {
          // First, get stats from IndexedDB
          const localStats = await flashcardIDB.getUserStudyStats(userId, courseId);
          
          // Then, try to fetch from Supabase
          const { count, error } = await supabase
            .from('user_flashcard_progress')
            .select('*', { count: 'exact', head: true })
            .eq('user_id', userId)
            .eq('course_id', courseId);
      
          if (error) throw error;
      
          if (count !== null) {
            const supabaseCount = count;
            
            // Get the count of custom flashcards from IndexedDB
            const customCards = await flashcardIDB.getCustomFlashcards(userId, courseId);
            const customCount = customCards.length;
            
            const totalCount = supabaseCount + customCount;
            
            // Update local storage with the new count
            await flashcardIDB.updateUserStudyStats(userId, courseId, totalCount);
            
            return { cardsCompleted: totalCount };
          }
      
          // If Supabase fetch fails, return local data
          return localStats;
        } catch (error) {
          console.error('Error fetching user study stats:', error);
          // If there's an error fetching from Supabase, return local data
          return flashcardIDB.getUserStudyStats(userId, courseId);
        }
    }


    //DELETE CUSTOM CARD SECTION

    private async syncDeletedCustomFlashcards() {
      const deletedItems = await flashcardIDB.getDeletedCustomFlashcards();
      
      // Handle deletions in batches
      for (let i = 0; i < deletedItems.length; i += this.batchSize) {
          const batch = deletedItems.slice(i, i + this.batchSize);
          try {
              await this.batchDeleteServerCustomFlashcards(batch);
              console.log(`Successfully synced deletion of custom flashcards batch`);
              
              // Remove successfully deleted items from IndexedDB
              for (const item of batch) {
                  await flashcardIDB.removeDeletedCustomFlashcardId(item.id);
              }
          } catch (error) {
              console.error(`Error during sync deletion of custom flashcards batch:`, error);
          }
      }
  }
    
  async deleteCustomFlashcard(identifier: string, courseId: string, userId: string, updateUI?: (id: string, updatedTags: string[]) => void) {
    console.log(`Attempting to delete custom flashcard. Identifier: "${identifier}", CourseId: ${courseId}, UserId: ${userId}`);
    
    if (!identifier) {
      console.error('Empty identifier provided to deleteCustomFlashcard');
      throw new Error('Invalid identifier: cannot delete flashcard with empty identifier');
    }

    try {
      await flashcardIDB.deleteCustomFlashcard(identifier, courseId);
      console.log(`Custom flashcard with identifier "${identifier}" marked for deletion in IndexedDB`);

      // Attempt to delete from Supabase immediately
      const { error } = await supabase
        .from('custom_flashcards')
        .delete()
        .match({ card_identifier: identifier, course_id: courseId, user_id: userId });

      if (error) {
        console.error('Error deleting custom flashcard from Supabase:', error);
      } else {
        console.log(`Custom flashcard with identifier ${identifier} successfully deleted from Supabase`);
      }

      // Fetch updated tags
      const updatedTags = await flashcardIDB.getAllUserTags(userId, courseId);

      // Update UI if callback provided
      if (updateUI) {
        updateUI(identifier, updatedTags);
      }

      return { success: true, updatedTags };
    } catch (error) {
      console.error('Error marking custom flashcard for deletion:', error);
      throw error;
    }
  }

    // CLEAN UP SYNC
    private async cleanupSyncQueue() {
        this.syncQueue = await Promise.all(this.syncQueue.filter(async (item) => {
            if (item.type === 'customFlashcard') {
                const card = await flashcardIDB.getCustomFlashcard(item.data.id);
                return card && card.synced === 0;
            } else if (item.type === 'userProgress') {
                const progress = await flashcardIDB.getUserProgress(
                    item.data.user_id,
                    item.data.course_id,
                    item.data.flashcard_id
                );
                return progress && progress.synced === 0;
            }
            return false;
        }));
    }
}

export const syncService = new SyncService();