import { createClient, SupabaseClient } from '@supabase/supabase-js'
import config from '../config'

const supabaseUrl = config.REACT_APP_SUPABASE_URL
const supabaseAnonKey = config.REACT_APP_SUPABASE_ANON_KEY

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables')
}

export const supabase: SupabaseClient = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true
  }
})

interface UserQuestionResponse {
  user_id: string;
  question_id: string;
  course_id: string;
  selected_answer: string;
  is_correct: boolean;
}

interface UserActivity {
  user_id: string;
  course_id: string;
  activity_type: string;
  activity_id: string;
  result?: string;
}

export const insertUserQuestionResponse = async (data: UserQuestionResponse): Promise<void> => {
  const { error } = await supabase.from('user_question_responses').insert(data)
  if (error) throw error
}

export const insertUserActivity = async (data: UserActivity): Promise<void> => {
  const { error } = await supabase.from('user_activity').insert(data)
  if (error) throw error
}