import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useParams } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider, useAuth } from './components/AuthProvider';
import { ProductProvider } from './components/ProductProvider';
import { UserCourseAccessProvider } from './components/UserCourseAccessProvider';
import { ThemeProvider } from './contexts/ThemeContext';
import { api } from './services/api';
import Layout from './components/layout/Layout';
import Home from './pages/Home';
import Products from './pages/Products';
import Course from './pages/Course';
import Profile from './pages/Profile';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Logout from './components/auth/Logout';
import ForgotPassword from './components/auth/ForgotPassword';
import VerifyEmail from './components/auth/VerifyEmail';
import ResetPassword from './components/auth/ResetPassword';
import TextSelectionPopup from './components/course/PiracyProtection/TextSelectionPopup';
import Mentor from './components/course/Mentor';
import { FlashcardDBProvider } from './contexts/FlashcardDBContext';

export const ModalContext = React.createContext<{
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isModalOpen: false,
  setIsModalOpen: () => {},
});

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000, // 1 hour
      cacheTime: 24 * 60 * 60 * 1000, // 24 hours
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) return <div>Loading...</div>;
  if (!user) return <Navigate to="/login" replace />;
  return <>{children}</>;
};

const CourseRoute: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const { isModalOpen } = React.useContext(ModalContext);

  return (
    <>
      <Course />
      {!isModalOpen && courseId && <TextSelectionPopup courseId={courseId} />}
    </>
  );
};

const PublicOnlyRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) return <div>Loading...</div>;
  if (user) return <Navigate to="/" replace />;
  return <>{children}</>;
};

function AuthListener() {
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      api.clearAllCache();
      queryClient.clear(); // Clear React Query cache when user logs out
    }
  }, [user]);

  return null;
}

function AppRoutes() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log('AppRoutes: Current path:', location.pathname);
  }, [location.pathname]);

  return (
    <ModalContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      <Layout>
        <AuthListener />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          } />
          <Route path="/course/:courseId/*" element={
            <ProtectedRoute>
              <CourseRoute />
            </ProtectedRoute>
          } />
          <Route path="/course/:courseId/*" element={
            <ProtectedRoute>
              <Course />
            </ProtectedRoute>
          } />
          <Route path="/profile" element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          } />
          <Route path="/mentor" element={
            <ProtectedRoute>
              <Mentor />
            </ProtectedRoute>
          } />
          <Route path="/login" element={
            <PublicOnlyRoute>
              <Login />
            </PublicOnlyRoute>
          } />
          <Route path="/register" element={
            <PublicOnlyRoute>
              <Register />
            </PublicOnlyRoute>
          } />
          <Route path="/forgot-password" element={
            <PublicOnlyRoute>
              <ForgotPassword />
            </PublicOnlyRoute>
          } />
          <Route path="/reset-password" element={
            <PublicOnlyRoute>
              <ResetPassword />
            </PublicOnlyRoute>
          } />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Layout>
    </ModalContext.Provider>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <UserCourseAccessProvider>
            <ProductProvider>
              <ThemeProvider>
                <FlashcardDBProvider>
                  <AppRoutes />
                </FlashcardDBProvider>
              </ThemeProvider>
            </ProductProvider>
          </UserCourseAccessProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;