import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown } from 'react-feather';
import { useAuth } from '../AuthProvider';
import { useProducts } from '../ProductProvider';
import { useUserCourseAccess } from '../UserCourseAccessProvider';

interface Product {
  id: string;
  title: string;
  description: string;
  // Add any other properties that are in the ProductProvider's Product interface
}

interface CourseDropdownProps {
  currentCourseId: string | undefined;
}

const CourseDropdown: React.FC<CourseDropdownProps> = ({ currentCourseId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [courses, setCourses] = useState<Product[]>([]);
  const [currentCourseTitle, setCurrentCourseTitle] = useState('Select a Course');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const { getProduct } = useProducts();
  const { userCourseAccess } = useUserCourseAccess();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      if (user && userCourseAccess.length > 0) {
        try {
          const courseDetails = await Promise.all(
            userCourseAccess.map(access => getProduct(access.course_id))
          );
          setCourses(courseDetails.filter((course): course is Product => course !== null));
        } catch (error) {
          console.error('Failed to fetch courses:', error);
        }
      }
    };

    fetchCourses();
  }, [user, userCourseAccess, getProduct]);


  useEffect(() => {
    if (currentCourseId && courses.length > 0) {
      const currentCourse = courses.find(course => course.id === currentCourseId);
      if (currentCourse) {
        setCurrentCourseTitle(currentCourse.title);
      } else {
        setCurrentCourseTitle('Select a Course');
      }
    } else {
      setCurrentCourseTitle('Select a Course');
    }
  }, [currentCourseId, courses]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCourseSelect = (course: Product) => {
    if (course.id !== currentCourseId) {
      setCurrentCourseTitle(course.title);
      setIsOpen(false);
      navigate(`/course/${course.id}/study-plan`);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center text-xl md:text-2xl font-bold text-gray-800 dark:text-white focus:outline-none"
      >
        {currentCourseTitle}
        <ChevronDown size={20} className="ml-2 text-gray-600 dark:text-gray-300" />
      </button>
      {isOpen && (
        <div className="absolute left-0 mt-2 w-64 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1">
            {courses.map((course) => (
              <button
                key={course.id}
                className={`block w-full text-left px-4 py-2 text-xs ${
                  course.id === currentCourseId
                    ? 'bg-gray-100 dark:bg-gray-600 text-gray-900 dark:text-white'
                    : 'text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600'
                }`}
                onClick={() => handleCourseSelect(course)}
              >
                {course.title}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseDropdown;