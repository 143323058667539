import React from 'react';
import { Link } from 'react-router-dom';
import { truncateText } from '../../utils/helpers';

interface Product {
  id: string;
  title: string;
  description: string;
}

interface ProductListProps {
  products: Product[];
}

function ProductList({ products }: ProductListProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {products.map((product) => (
        <div key={product.id} className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">{product.title}</h3>
            <p className="text-gray-600 mb-4">{truncateText(product.description, 100)}</p>
            <Link 
              to={`/course/${product.id}/study-plan`}
              className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              View Study Plan
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductList;